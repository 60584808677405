import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Checkbox } from '@veneer/core';
import React from 'react';

const FilterItem = (props: {
  filterLabel: string;
  filterItems: any;
  handleFilterOption: (e) => void;
}) => {
  return (
    <>
      <HorizontalLineText>
        <span>{props.filterLabel}</span>
        <div></div>
      </HorizontalLineText>
      <FilterCheckBoxContent>
        {props.filterItems ? (
          props.filterItems.map((item, idx) => (
            <Checkbox
              label={item}
              key={idx}
              id={`filter-checkbox-${idx}`}
              onChange={props.handleFilterOption}
              customStyle={{ padding: '10px 0' }}
            />
          ))
        ) : (
          <NoItems>No items</NoItems>
        )}
      </FilterCheckBoxContent>
    </>
  );
};

export default FilterItem;

const FilterCheckBoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalLineText = styled.div`
  margin-top: 25px;

  span {
    display: flex;
    flex-direction: row;
    font-family: Forma DJR UI;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${tokens.color.gray4};
  }

  span::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${tokens.color.gray3};
    margin: auto;
    margin-left: 5px;
  }
`;

const NoItems = styled.span`
  margin-left: 10px;
  color: ${tokens.color.gray4};
  font-weight: bold;
`;
