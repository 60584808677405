import React, { useState, useEffect, useMemo, useRef } from 'react';
import useTableColumn from 'src/hooks/useTableColumn';
import { useSearch } from 'src/hooks/useSearch';
import { fetchAllWithLoop, fetchAllAsynchronously, getGroups } from 'src/utils/groupMethods';
import { useDispatch } from 'react-redux';
import { fleetMgtAPI } from 'src/api/fleetMgt';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { InitialLoading } from 'src/components/molecule';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { DeviceMainTemplate } from 'src/components/template';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { DeviceTableRefreshType, fullListOption, solutionID } from 'src/utils/constants';
import { getProps, getTableId, setTableColumns } from 'src/utils/commonMethods';
import {
  storeTableData,
  storeDeviceTableRefresh,
  storeTotalDeviceCountInGroup,
  storeTableSortClicked,
  storeSortById,
  storeSortByType,
} from 'src/store/devices/action';
import {
  storeMainGroupData,
  storeMainGroupRefresh,
  storeUngroupedGroupUuid,
  storeAllDevicesGroupUuid,
  storeMainGroupSelectedId,
  storeMainGroupSelectedName,
} from 'src/store/devicesGroup/action';
import { fleetProxyAPI } from 'src/api/fleetProxy';
import { useSort } from 'src/hooks/useSort';
import { useRefreshHook } from 'src/hooks/useRefreshHook';

type TDeviceMainPageProps = {
  setShowEditGroupModal: (boolean) => void;
};

const DeviceMainPage = (props: TDeviceMainPageProps) => {
  const { customRelativePath, isWex } = useShellRootContext();
  const { devicesState, devicesGroupState, devicesRnPmState } = useStoreState();
  const { contentsDevicesCountRnPm, contentsCollectionsReadRnPm } = devicesRnPmState;
  const { mainGroupSelectedId, mainGroupRefresh, mainGroupData } = devicesGroupState;
  const {
    deviceTableRefresh,
    searchItem,
    sortClicked,
    sortById,
    sortByType,
    currentPage,
    cfgApiRequestOptions,
  } = devicesState;

  const { getCollections, getTypedCollectionById } = collectionAPI();
  const { getCompliance } = fleetMgtAPI();
  const { getProxiesByTenant } = fleetProxyAPI();
  const dispatch = useDispatch();
  const { refreshDevicesTableWithoutApi } = useRefreshHook();

  const tableId = getTableId(customRelativePath);
  const enableRnPm = contentsDevicesCountRnPm && contentsCollectionsReadRnPm;
  const serviceID = getProps().serviceID;
  const complianceList = useRef([]);
  const fleetProxyList = useRef([]);
  const currentGroupId = useRef(mainGroupSelectedId);

  const [isFetchingGroup, setIsFetchingGroup] = useState(true);
  const [isFetchingAdditionalInfo, setIsFetchingAdditionalInfo] = useState(true);
  const [isFetchingTable, setIsFetchingTable] = useState(true);
  const [errorGroup, setErrorGroup] = useState(undefined);
  const [errorTable, setErrorTable] = useState(undefined);
  const [enableRenderTable, setEnableRenderTable] = useState(false);

  /* This is used when Search, Sort is activated */
  const [entireDataCache, setEntireDataCache] = useState([]);
  const searchActivated = useMemo(
    () => searchItem != '' || cfgApiRequestOptions.search,
    [searchItem],
  );
  const sortActivated = useMemo(() => sortClicked || sortById != 'uid', [sortClicked, sortById]);
  const { tableHandler } = useTableHandler();
  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(columnConfig);
  const { triggerSort } = useSort(columnConfig);

  useEffect(() => {
    const sortInfo = sessionStorage.getItem(tableId + '-sort');
    if (sortInfo) {
      dispatch(storeSortById(sortInfo.split('|')[0]));
      dispatch(storeSortByType(sortInfo.split('|')[1]));
    } else {
      sessionStorage.setItem(tableId + '-sort', 'addedDate|descending');
      dispatch(storeSortById('addedDate'));
      dispatch(storeSortByType('descending'));
    }
    apiGetGroups()
      .then((allGroupId) =>
        apiFetchDeviceAdditionalInfo(allGroupId)
          .then()
          .finally(() => {
            setIsFetchingAdditionalInfo(false);
          }),
      )
      .finally(() => {
        setIsFetchingGroup(false);
      });
  }, []);

  useEffect(() => {
    if (errorGroup !== undefined || errorTable !== undefined) {
      setIsFetchingGroup(false);
      setIsFetchingTable(false);
      dispatch(storeAllDevicesGroupUuid(''));
      dispatch(storeUngroupedGroupUuid(''));
    }
  }, [errorGroup, errorTable]);

  useEffect(() => {
    if (isFetchingGroup == false && isFetchingAdditionalInfo == false && errorGroup == undefined) {
      setIsFetchingTable(true);
      setEnableRenderTable(false);

      apiGetTableResponse(true)
        .then(({ devices, isError }) => {
          setTableColumns(tableId, columnConfig);
          handlePageData(devices, true);
          if (isError) {
            setErrorTable(isError);
          } else {
            setErrorTable(undefined);
          }
        })
        .finally(() => {
          setIsFetchingTable(false);
        });
    }
  }, [isFetchingGroup, isFetchingAdditionalInfo, mainGroupSelectedId]);

  useEffect(() => {
    if (mainGroupRefresh) {
      apiGetGroups().finally(() => {
        dispatch(storeMainGroupRefresh(false));
      });
    }
  }, [mainGroupRefresh]);

  useEffect(() => {
    if (deviceTableRefresh == DeviceTableRefreshType.NONE) {
      return;
    }

    apiGetTableResponse(deviceTableRefresh == DeviceTableRefreshType.REFRESH_WITH_API_CALL)
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices, deviceTableRefresh == DeviceTableRefreshType.REFRESH_WITH_API_CALL);
        if (isError) {
          setErrorTable(isError);
        } else {
          setErrorTable(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
      });
  }, [deviceTableRefresh]);

  useEffect(() => {
    let completeGetTable = false;
    let completeGetGroup = false;

    completeGetGroup = isFetchingGroup == false && (errorGroup == undefined || isWex);
    completeGetTable = isFetchingTable == false && (errorTable == undefined || isWex);

    setEnableRenderTable(completeGetGroup && completeGetTable);
  }, [isFetchingGroup, isFetchingTable]);

  /*  This is for Pagination
   * when table data is changed without calling API, and reuse saved list
   * search/sort is active, and page changes
   * */
  useEffect(() => {
    if (searchItem == '' && sortById == 'uid') {
      return;
    }

    handlePageData(entireDataCache, false);
  }, [currentPage]);

  /*  This is for Sort
   * when table data is changed without calling API, and reuse saved list
   * */
  useEffect(() => {
    if (!sortClicked) {
      return;
    }
    handlePageData(entireDataCache, false);
  }, [sortClicked]);

  useEffect(() => {
    if (mainGroupSelectedId == '' && searchItem == '') {
      return;
    }

    if (searchItem == '' || !!cfgApiRequestOptions.search) {
      const deviceCount = mainGroupData.filter((group) => group.id === mainGroupSelectedId)[0]
        .devices;
      dispatch(storeTotalDeviceCountInGroup(deviceCount));
    }

    refreshDevicesTableWithoutApi();
  }, [searchItem]);

  const handlePageData = (devices, isApiCall) => {
    let items;

    if (devices !== undefined) {
      items = JSON.parse(JSON.stringify(devices));
    }

    // Insert complianceList into items
    items = items?.map((item) => ({
      ...item,
      complianceList: complianceList.current.find((comp) => comp.deviceId === item.deviceId) || {},
      // Insert connectivity with the result from CS API
      connectivity: item.connTypes?.includes('Proxy')
        ? fleetProxyList.current
            .find((comp) => item.fleetProxyId.includes(comp.id))
            ?.connectionState.status.toLowerCase() ?? 'online'
        : item.status.connectionState,
    }));

    /*
     * Handle combinations of search and sort
     * */
    const currenTableData = isApiCall ? items : entireDataCache;
    if (sortActivated) {
      const { pagedItems, entireItems, count, valid } = triggerSort(
        currenTableData,
        sortById,
        sortByType,
      );
      if (valid) {
        items = pagedItems;
        dispatch(storeTotalDeviceCountInGroup(count));
        setEntireDataCache(entireItems);
      }
      dispatch(storeTableSortClicked(false)); //reset sortClicked
    }

    if (searchActivated) {
      const { pagedItems, entireItems, count, valid } = triggerSearch(
        currenTableData,
        searchItem,
        currentPage,
      );
      if (valid) {
        items = pagedItems;
        dispatch(storeTotalDeviceCountInGroup(count));
      }
    }
    if (!searchActivated && !sortActivated) {
      setEntireDataCache(currenTableData);
      dispatch(storeTotalDeviceCountInGroup(currenTableData.length));
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
    currentGroupId.current = mainGroupSelectedId;
  };

  const getRequestParams = (params) => {
    let newParams = params;

    if (serviceID !== undefined && params.hasOwnProperty('filter')) {
      delete newParams.filter;
    }
    if (serviceID == solutionID.secureFleetManger) {
      newParams = { ...params, filter: serviceID };
    }
    if (params.search) {
      newParams = { ...params, ...fullListOption };
    }
    if (sortClicked) {
      newParams = { ...params, ...fullListOption };
    }

    if (cfgApiRequestOptions.search) {
      newParams = { ...cfgApiRequestOptions, ...fullListOption };
    }

    return newParams;
  };

  const apiFetchDeviceAdditionalInfo = async (groupId) => {
    setIsFetchingAdditionalInfo(true);
    await apiGetCompliance(groupId);
    await apiGetFleetProxy(groupId);
  };

  // To get group data
  const apiGetGroups = async () => {
    console.log('[devices] apiGetGroups');
    return await getGroups(getCollections, setGroups, setErrorGroup);
  };

  const apiGetCompliance = async (group) => {
    console.log('[devices] apiGetCompliance');
    const setCompliance = (response) => {
      complianceList.current = response;
    };
    return await fetchAllAsynchronously(
      getCompliance,
      setCompliance,
      setErrorTable,
      group.id,
      group.devices,
      'compliance',
      200,
    );
  };

  const apiGetFleetProxy = async (group) => {
    console.log('[devices] apiGetFleetProxy');
    const setFleetProxyConnectionState = (response) => {
      fleetProxyList.current = response;
    };

    return await fetchAllWithLoop(
      getProxiesByTenant,
      setFleetProxyConnectionState,
      setErrorTable,
      group.id,
      'fleetproxy',
    );
  };

  const setGroups = (newGroups) => {
    const allDevicesGroupObj = newGroups.find((group) => group.name === 'All');
    const ungroupedGroupObj = newGroups.find((group) => group.name === 'Ungrouped');

    const allDevicesGroupUuid = allDevicesGroupObj.id;
    const ungroupedGroupUuid = ungroupedGroupObj.id;
    const totalDeviceCountInGroup = allDevicesGroupObj.devices ?? 0;

    if (mainGroupSelectedId == '') {
      dispatch(storeMainGroupSelectedId(allDevicesGroupUuid));
      dispatch(storeMainGroupSelectedName('All'));
    }

    dispatch(storeAllDevicesGroupUuid(allDevicesGroupUuid));
    dispatch(storeUngroupedGroupUuid(ungroupedGroupUuid));
    dispatch(storeMainGroupData(newGroups));
    dispatch(storeTotalDeviceCountInGroup(totalDeviceCountInGroup));

    return newGroups.find((group) => group.name === 'All');
  };

  const fetchAllDevices = async (_reqParam, _selectedGroupId) => {
    if (!_selectedGroupId) {
      return { response: { contents: [] } };
    }
    let _response = [];
    const selectedGroup = mainGroupData.find((grp) => grp.id == _selectedGroupId);

    const setAllDevices = (_data) => {
      _response = _data;
    };
    const geTypedCollectionAPI = async (_Id, _offset, _limit) => {
      return await getTypedCollectionById(_Id, {
        ..._reqParam,
        offset: _offset,
        limit: _limit,
      });
    };
    const devices = await fetchAllAsynchronously(
      geTypedCollectionAPI,
      setAllDevices,
      setErrorTable,
      selectedGroup.id,
      selectedGroup.devices,
      'collection',
      1000,
    );
    return { response: { contents: devices } };
  };

  const apiGetTableResponse = async (isGroupIdChanged) => {
    console.log(`[devices] apiGet Table Response`);

    const reqParam = getRequestParams(cfgApiRequestOptions);

    const fetch = async () => {
      if (isGroupIdChanged) {
        console.log('[devices] fetch all data');
        return await fetchAllDevices(reqParam, mainGroupSelectedId);
      } else {
        console.log('[devices] no fetch, return cached data ');
        return { response: { contents: entireDataCache } };
      }
    };

    const { response } = await fetch();

    return {
      devices: response?.contents,
      isError: false,
    };
  };

  const memoRenderComponent = useMemo(() => {
    if ((!isFetchingGroup && !isFetchingTable && isWex) || (enableRenderTable && enableRnPm)) {
      console.log('[devices] render <DeviceMainTemplate />');
      return (
        <DeviceMainTemplate
          errorTable={errorTable}
          loadingTable={isFetchingTable}
          errorGroup={errorGroup}
          columnConfig={columnConfig}
          setShowEditGroupModal={props.setShowEditGroupModal}
        />
      );
    } else {
      return <></>;
    }
  }, [enableRenderTable]);

  return (
    <>{isFetchingGroup || isFetchingTable ? <InitialLoading /> : <>{memoRenderComponent}</>}</>
  );
};

export default DeviceMainPage;
