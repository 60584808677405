import React from 'react';
import moment from 'moment';
import { t } from 'src/utils/commonMethods';
import { Button } from '@veneer/core';
import { exportCSVFile } from 'src/utils/exportMethods';

type TExportActionButtonProps = {
  setShowModal: (e: boolean) => void;
  columnConfig: any;
  columnContents: any;
  customRelativePath: string;
  tenantName: string;
  useToast: any;
};

const TableExportActionButton = (props: TExportActionButtonProps) => {
  const renderSuccess = () => {
    props.useToast.addToast({
      id: 'success',
      type: 'positive',
      text: t('table.export_success_msg'),
    });
  };

  const exportAction = async () => {
    const headers = props.columnConfig
      .cfgColumnsList()
      .map((col) => col.id)
      .filter((id) => id !== 'uid');

    const deviceData = Object.entries(props.columnContents).map((array) => {
      const entry = array[1];
      return entry;
    });

    const accountName = props.tenantName;

    await exportCSVFile(
      `DeviceList_${
        props.customRelativePath === '/devices' ? 'Devices' : 'Solutions'
      }_${accountName}_${moment().format('MMDDYYYY_hhmm')}`, //DeviceList_[%MFE Name%]_[%Customer Name%]_[%MMDDYYYY_HHMM%] moment(data).format(dateFormat.default);
      deviceData,
      headers,
      props.columnConfig.cfgColumnsList(),
    );

    renderSuccess();

    props.setShowModal(false);
  };

  return (
    <Button data-testid="export-button" onClick={() => exportAction()}>
      {t('table.export')}
    </Button>
  );
};

export default TableExportActionButton;
