import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { Button } from '@veneer/core';
import { EditGroupActions, EditGroupTree } from 'src/components/organism';
import {
  storeMainGroupSelectedId,
  storeMainGroupSelectedName,
} from 'src/store/devicesGroup/action';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TEditGroupModalProps = {
  enableRender: boolean;
  showEditGroupModal: boolean;
  groupData: any[];
  allDevicesGroupUuid: string;
  ungroupedGroupUuid: string;
  selectedGroupsIdList: string[];
  selectedGroupName: string;
  parentGroupList: any[];
  actionCount: number;
  setShowEditGroupModal: (e) => void;
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
  refreshEditGroupModalPage: () => void;
};

const EditGroupModalTemplate = (props: TEditGroupModalProps) => {
  const dispatch = useDispatch();
  const { devicesGroupState } = useStoreState();
  const { refreshPageWithApi } = useRefreshHook();
  const { isWex, mode } = useShellRootContext();

  const refreshAll = () => {
    dispatch(storeMainGroupSelectedId(devicesGroupState.allDevicesGroupUuid));
    dispatch(storeMainGroupSelectedName('All'));
    refreshPageWithApi();
  };

  return (
    <>
      <Title isWex={isWex} mode={mode} data-testid="edit-group-modal-title">
        {t('group.edit_groups')}
      </Title>
      <BodyArea isWex={isWex} mode={mode}>
        <SubTitleSection data-testid="edit-group-modal-desc">
          {t('group.edit_groups_description')}
        </SubTitleSection>

        <MainContent>
          <EditGroupTree {...props} />
          <EditGroupActions {...props} />
        </MainContent>
      </BodyArea>
      <FooterArea isWex={isWex} mode={mode}>
        <ClosePanel>
          <Button
            appearance="secondary"
            onClick={() => {
              if (props.actionCount > 0) {
                props.setSelectedGroupsIdList([]);
                refreshAll();
              }
              props.setShowEditGroupModal(false);
            }}
            small={true}
            data-testid="edit-group-modal-cancel-button"
          >
            {t('group.close')}
          </Button>
        </ClosePanel>
      </FooterArea>
    </>
  );
};

const Title = styled.div`
  font-weight: bold;
  display: flex;
  ${(props) => {
    if (!props.isWex || props.mode != 'dark') return 'background: #ffffff';
  }};
  padding: 15px 24px 16px 24px;
  font-size: 24px;
  line-height: 28px;
  align-items: center;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;

  border-radius: 10px;
`;

const BodyArea = styled.div`
  ${(props) => {
    if (!props.isWex || props.mode != 'dark') return 'background: #f8f8f8';
  }};
  width: 100%;

  > div {
    padding-left: 24px;
  }
`;

const SubTitleSection = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  min-height: 48px;
  padding-top: 30px;
  margin-bottom: 10px;
`;

const MainContent = styled.div`
  display: flex;
  padding-right: 24px;
  height: 550px;
`;

const FooterArea = styled.div`
  width: 100%;
  min-height: 96px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  ${(props) => {
    if (!props.isWex || props.mode != 'dark') return 'background: #f8f8f8';
  }};

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
const ClosePanel = styled.div`
  width: 98%;
  display: flex;
  justify-content: right;

  > button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

export default EditGroupModalTemplate;
