import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedUseGetDevices } from './deviceCacheRefinery';
import { isMockUpMode } from 'src/utils/commonMethods';
import getDeviceInfoMock from 'src/mock/deviceCache/get-devices.json';

export const deviceCacheAPI: any = (appName?) => {
  const { getApiClient } = useApiClient();

  const client = getApiClient(service.deviceCache);

  const getDevices = async (reqParams) => {
    let response, error;
    const { offset, limit } = reqParams;

    if (isMockUpMode()) {
      response = refinedUseGetDevices(getDeviceInfoMock);

      return { response, error };
    }

    await client
      .list(appName?.slice(0, 19), offset, limit)
      .then((res) => {
        response = refinedUseGetDevices(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getDevices,
  };
};
