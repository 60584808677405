import { useDispatch } from 'react-redux';
import { storeMainGroupRefresh } from 'src/store/devicesGroup/action';
import { storeDeviceTableRefresh, storeSelectedItems } from 'src/store/devices/action';
import { DeviceTableRefreshType } from 'src/utils/constants';

export const useRefreshHook: any = () => {
  const dispatch = useDispatch();

  const refreshDevicesTableWithApi = () => {
    dispatch(storeSelectedItems([]));
    dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
    setTimeout(
      () => dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.REFRESH_WITH_API_CALL)),
      100,
    );
  };

  const refreshDevicesTableWithoutApi = () => {
    dispatch(storeSelectedItems([]));
    dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
    setTimeout(
      () => dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.REFRESH_WITHOUT_API_CALL)),
      100,
    );
  };

  const refreshGroup = () => {
    dispatch(storeMainGroupRefresh(true));
  };

  const refreshPageWithApi = () => {
    refreshGroup();
    refreshDevicesTableWithApi(); // doesn't call API to refresh the table
  };

  const refreshPageWithoutApi = () => {
    refreshGroup();
    refreshDevicesTableWithoutApi(); // doesn't call API to refresh the table
  };

  return {
    refreshPageWithApi,
    refreshPageWithoutApi,
    refreshGroup,
    refreshDevicesTableWithApi,
    refreshDevicesTableWithoutApi,
  };
};
