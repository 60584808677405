import styled from 'styled-components';
import React from 'react';
import { Scrollbar, TreeView } from '@veneer/core';
import { useDispatch } from 'react-redux';
import { getTreeViewParentNodes } from 'src/utils/groupMethods';
import {
  storeMoveGroupModalSelectedId,
  storeMoveGroupModalSelectedName,
} from 'src/store/devicesGroup/action';
import { InfiniteScrollTree } from 'src/components/molecule/index';

type TMoveGroupTreeProps = {
  groups: any;
  useToast: any;
  ungroupedGroupUuid: string;
  allDevicesGroupUuid: string;
  contentsDevicesCountRnPm: boolean;
  contentsCollectionsReadRnPm: boolean;
};

const MoveGroupTree = (props: TMoveGroupTreeProps) => {
  const dispatch = useDispatch();
  const showGroupTree =
    props.contentsDevicesCountRnPm &&
    props.contentsCollectionsReadRnPm &&
    props.groups.length !== 0;

  const nodes = getTreeViewParentNodes(
    props.groups,
    props.allDevicesGroupUuid,
    props.ungroupedGroupUuid,
  );

  const handleMoveGroupModalData = (id) => {
    dispatch(storeMoveGroupModalSelectedId([id]));
    dispatch(
      storeMoveGroupModalSelectedName(
        props.groups.find((group) => {
          return group.id == id;
        }).name,
      ),
    );
  };

  const renderMoveGroupTree = (displayedNodes) => {
    return (
      <TreeView
        data-testid="moveto-group-tree"
        nodes={displayedNodes}
        defaultExpandedNodes={[props.allDevicesGroupUuid]}
        onChange={(e, id) => {
          handleMoveGroupModalData(id);
        }}
      />
    );
  };

  if (!showGroupTree) return <></>;

  return (
    <Border>
      <Scrollbar
        customStyle={{
          overflow: 'auto',
          width: '532px',
        }}
      >
        <Wrapper
          data-testid="move-group-tree-wrapper"
          id={'move-group-tree__wrapper'}
          allDevicesGroupUuid={props.allDevicesGroupUuid}
          ungroupedGroupUuid={props.ungroupedGroupUuid}
        >
          <InfiniteScrollTree nodes={nodes} render={renderMoveGroupTree} id={'move-group-tree'} />
        </Wrapper>
      </Scrollbar>
    </Border>
  );
};

const Border = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid rgba(33, 33, 33, 0.1);
`;

const Wrapper = styled.div`
  max-height: 320px;
  margin-right: 24px;

  li div span {
    line-height: 20px;
  }

  li[id='${(props) => props.allDevicesGroupUuid}'],
  li[id='${(props) => props.ungroupedGroupUuid}'] {
    > div:first-child {
      pointer-events: none;
      background: none !important;

      .expand-icon {
        pointer-events: all;
      }
    }

    > div:first-child > span {
      color: #dcdcdc;
    }
  }

  li[id='${(props) => props.allDevicesGroupUuid}'] > li > div {
    margin: 0;
    white-space: nowrap;
  }
`;

export default MoveGroupTree;
