import { filterOptions, store, DeviceTableRefreshType } from 'src/utils/constants';

const initialState = {
  //table config
  startRootComponent: false,
  isDevicesTab: null, // boolean
  isFromDetails: null,
  isFromUnassigned: false,
  deviceTableRefresh: DeviceTableRefreshType.NONE,

  //table data
  tableData: [],
  totalDeviceCountInGroup: 0,
  initialColumns: [],
  selectedItems: [],
  cfgApiRequestOptions: filterOptions,
  tableFilter: null,
  sortById: 'uid',
  sortByType: null,
  sortClicked: false,

  columnContents: {},
  searchItem: '',
  currentPage: 1,
  pageSize: filterOptions.limit,
};

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.devices.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.devices.IS_DEVICES_TAB:
      return {
        ...state,
        isDevicesTab: action.payload,
      };
    case store.devices.IS_NAVIGATED_FROM_DETAILS:
      return {
        ...state,
        isFromDetails: action.payload,
      };
    case store.devices.IS_FROM_UNASSIGNED:
      return {
        ...state,
        isFromUnassigned: action.payload,
      };
    case store.devices.DEVICE_TABLE_REFRESH:
      return {
        ...state,
        deviceTableRefresh: action.payload,
      };
    case store.devices.STORE_INITIAL_COLUMNS:
      return {
        ...state,
        initialColumns: action.payload,
      };
    case store.devices.STORE_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };
    case store.devices.STORE_TOTAL_DEVICE_COUNT_IN_GROUP:
      return {
        ...state,
        totalDeviceCountInGroup: action.payload,
      };
    case store.devices.STORE_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    case store.devices.STORE_TABLE_SORT_ID:
      return {
        ...state,
        sortById: action.payload,
      };
    case store.devices.STORE_TABLE_SORT_TYPE:
      return {
        ...state,
        sortByType: action.payload,
      };
    case store.devices.STORE_TABLE_SORT_CLICKED:
      return {
        ...state,
        sortClicked: action.payload,
      };
    case store.devices.STORE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case store.devices.STORE_COLUMN_CONTENTS:
      return {
        ...state,
        columnContents: action.payload,
      };
    case store.devices.STORE_SEARCH_ITEM:
      return {
        ...state,
        searchItem: action.payload,
      };
    case store.devices.STORE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case store.devices.STORE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case store.devices.STORE_API_REQUEST_OPTIONS:
      return {
        ...state,
        cfgApiRequestOptions: action.payload,
      };
    case store.devices.RESET_DEVICES_REDUCER:
      return {
        startRootComponent: false,
        isDevicesTab: null, // boolean
        isFromDetails: null,
        isFromUnassigned: false,
      };
    default:
      return state;
  }
};

export default devicesReducer;
