import { createContext } from 'react';

const serviceID = null;
const ecpDeviceV55 = null;
const stack = null;
const shell = null;
const localization = null;
const events = null;
const useToast = null;
const authProvider = null;
const navigation = null;
const getBreadcrumb = null;
const setBreadcrumb = null;
const removeBreadcrumb = null;
const showDevicesGroupCUD = null;
const showDevicesTableCheckbox = null;
const showDevicesTableActionArea = null;
const showDevicesGroupView = null;
const showDevicesMainHeader = null;
const columns = null;
const customInitialColumns = null;
const deviceDetailsComponent = null;
const deviceDetailsMfe = null;
const customRelativePath = null;
const featureFlags = null;
const tenantId = null;
const tenantName = null;
const accessControl = null;
const appName = null;
const appUuid = null;
const theme = null;
const isWex = null;
const mode = null;
const setShowAddDeviceModal = null;
const setAddDeviceModalTitle = null;
const setAddDeviceFooterButtonName = null;
const isFromApps = null;
const AppBar = null;
const closeDownloadAppConfigModal = null;
const hideDownloadAppConfigModal = null;
const demoEnabled = null;
const apiPath = null;
const demoModeOptions = null;
const requestOptions = null;

const shellRootContext = createContext({
  serviceID,
  ecpDeviceV55,
  stack,
  shell,
  localization,
  events,
  useToast,
  authProvider,
  navigation,
  getBreadcrumb,
  setBreadcrumb,
  removeBreadcrumb,
  showDevicesGroupCUD,
  showDevicesTableCheckbox,
  showDevicesTableActionArea,
  showDevicesGroupView,
  showDevicesMainHeader,
  columns,
  customInitialColumns,
  deviceDetailsComponent,
  deviceDetailsMfe,
  customRelativePath,
  featureFlags,
  tenantId,
  tenantName,
  accessControl,
  appName,
  appUuid,
  theme,
  isWex,
  mode,
  setShowAddDeviceModal,
  setAddDeviceModalTitle,
  setAddDeviceFooterButtonName,
  isFromApps,
  AppBar,
  closeDownloadAppConfigModal,
  hideDownloadAppConfigModal,
  demoEnabled,
  apiPath,
  demoModeOptions,
  requestOptions,
});

export default shellRootContext;
