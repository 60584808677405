import moment from 'moment/moment';

export const downloadJsonMethods = (deviceAppConfigs, devices) => {
  if (!Array.isArray(deviceAppConfigs)) {
    return;
  }

  const appName = deviceAppConfigs[0]?.appTitle ?? '--';
  const appVersion = deviceAppConfigs[0]?.appVersion ?? '--';
  const appUuid = deviceAppConfigs[0]?.appUuid ?? '--';

  const exportObj = {
    dateCreated: undefined,
    //resourceId: undefined,    // Commented properties exist in HPCC v1, but we will review them whether it's essential in ECP.
    //tenantResourceId: undefined,
    //solutionResourceId: undefined,
    solutionUuid: undefined,
    solutionVersion: undefined,
    solutionName: undefined,
    data: [],
  };

  exportObj.dateCreated = new Date(Date.now()).toISOString();
  //exportObj.resourceId = '--';
  //exportObj.tenantResourceId = '--';
  //exportObj.solutionResourceId = '--';
  exportObj.solutionUuid = appUuid;
  exportObj.solutionVersion = appVersion;
  exportObj.solutionName = appName;

  deviceAppConfigs.forEach((deviceAppConfigs) => {
    const exportAppConfigDevice = {
      deviceResourceId: undefined,
      modelName: undefined,
      serialNumber: undefined,
      assetNumber: undefined,
      state: undefined,
      //taskDescription: undefined,
      value: {},
    };

    const appConfigDevice = devices?.find(
      (device) => device.deviceId === deviceAppConfigs.deviceId,
    );

    if (appConfigDevice !== undefined) {
      exportAppConfigDevice.deviceResourceId = appConfigDevice.deviceId;
      exportAppConfigDevice.modelName = appConfigDevice.modelName.props.value;
      exportAppConfigDevice.serialNumber = appConfigDevice.serialNumber.props.value;
      exportAppConfigDevice.assetNumber = appConfigDevice.assetNumber.props.value;
      exportAppConfigDevice.state = deviceAppConfigs.configState === 'READ' ? 'Completed' : '--';

      const exportAppConfigData = {
        uuid: undefined,
        schema: undefined,
        data: undefined,
      };
      exportAppConfigData.uuid = appUuid;
      //exportAppConfigData.schema = null;
      try {
        const jsonConfigDataObj = JSON.parse(atob(deviceAppConfigs.configData));
        exportAppConfigData.data = jsonConfigDataObj;
      } catch (e) {
        exportAppConfigData.data = '--';
      }

      exportAppConfigDevice.value = exportAppConfigData;

      exportObj.data.push(exportAppConfigDevice);
    } else {
      console.log('Error: Not able to find the device from the response in downloadJsonMethods');
    }
  });

  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `AppConfiguration_${appName}_${_getDate()}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const _getDate = () => {
  return moment(new Date()).format('MMDDYYYY_HHmm');
};
