import React, { useEffect, useMemo, useState } from 'react';
import { DeviceTable, DownloadAppConfigWindow } from 'src/components/organism';
import styled from 'styled-components';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { ResizeObserver } from 'resize-observer';
import { Button } from '@veneer/core';
import { DeviceTableRefreshType } from 'src/utils/constants';

type TDeviceforAppTemplateProps = {
  error: boolean;
  loading: boolean;
  columnConfig: any;
};

const DeviceAppConfigTemplate = (props: TDeviceforAppTemplateProps) => {
  const { devicesState } = useStoreState();
  const { tableData, selectedItems, deviceTableRefresh, totalDeviceCountInGroup } = devicesState;
  const { showDevicesTableCheckbox, closeDownloadAppConfigModal, hideDownloadAppConfigModal } =
    useShellRootContext();
  const [bodyWidth, setBodyWidth] = useState(0);
  const [openDownloadAppConfigWindow, setOpenDownloadAppConfigWindow] = useState(false);

  const showCheckbox = showDevicesTableCheckbox ? 'multiSelection' : '';

  const onResize = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width } = entry.contentRect;
      setBodyWidth(width);
    }
  });

  useEffect(() => {
    onResize.observe(document.querySelector('div[id="solutions-apps-overview-tabs"]'));
  }, []);

  const handleWindowClose = (value) => {
    setOpenDownloadAppConfigWindow(value);
    closeDownloadAppConfigModal();
  };

  const handleDownload = () => {
    setOpenDownloadAppConfigWindow(true);
    hideDownloadAppConfigModal();
  };

  const memoTable = useMemo(() => {
    return (
      <DeviceTable
        error={props.error}
        loading={deviceTableRefresh != DeviceTableRefreshType.NONE}
        tableData={tableData}
        deviceCount={totalDeviceCountInGroup}
        showCheckbox={showCheckbox}
        showHeaderActionArea={true}
        showExportAll={false}
        columnConfig={props.columnConfig}
        columnReorder={false}
      />
    );
  }, [tableData, deviceTableRefresh]);

  return (
    <Wrapper data-testid={'device-for-app-template__wrapper'}>
      {memoTable}

      {openDownloadAppConfigWindow && (
        <DownloadAppConfigWindow
          data-testid="download-app-config-window"
          selectedTableData={selectedItems}
          setOpenNewWindow={handleWindowClose}
        />
      )}
      <ButtonWrapper>
        {selectedItems.length > 0 && (
          <Button
            data-testid={'app-config-download-button'}
            appearance="primary"
            onClick={handleDownload}
          >
            Download
          </Button>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: inherit;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  button {
    z-index: 10;
    bottom: -41px;
    right: 8px;
  }
`;

export default DeviceAppConfigTemplate;
