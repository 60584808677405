import { refined } from 'src/utils/commonMethods';

export const refinedFetchAppList = (data) => {
  return {
    apps:
      data.apps?.length > 0
        ? data.apps
            .map((obj) => ({
              deviceId: refined(obj.deviceId),
              appTitle: refined(obj.appTitle),
              appUuid: refined(obj.appUuid),
              appState: refined(obj.appState),
              appVersion: refined(obj.appVersion),
              deployState: refined(obj.deployState),
              configState: refined(obj.configState),
              configData: refined(obj.configData),
            }))
            .filter((refinedObj) => refinedObj.appTitle !== '--')
        : [],
  };
};
