import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';

export const useSort = (columnConfig) => {
  const { tableHandler } = useTableHandler();
  const { devicesState } = useStoreState();
  const { cfgApiRequestOptions, currentPage } = devicesState;

  const getSliceEnd = (option, length) => {
    const totalPageCount = Math.floor(length / option.limit) + 1;
    const currentPage = option.offset;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * option.limit;
    } else {
      return length;
    }
  };

  const recalculatePage = (newSortedItems, currentPage) => {
    const newPageOption = {
      offset: currentPage - 1,
      limit: cfgApiRequestOptions.limit,
    };
    const lastIndex = getSliceEnd(newPageOption, newSortedItems.length);
    return newSortedItems.slice(newPageOption.offset * newPageOption.limit, lastIndex);
  };

  const sort = (id, type, items) => {
    const tableContents = tableHandler.getColumnContents(items);
    const entries = Object.entries(tableContents).map(([id, entry]) => {
      const val = columnConfig.cfgColumnsList().reduce((r, col) => {
        r[col.id] = entry[col.id];
        return r;
      }, {});
      val['id'] = id;
      return val;
    });

    const sortedOrder = entries.sort((a, b) => {
      if (id == 'addedDate') {
        const dateA = new Date(a[id]);
        const dateB = new Date(b[id]);
        return type === 'ascending'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else return type === 'ascending' ? a[id].localeCompare(b[id]) : b[id].localeCompare(a[id]);
    });
    const orderMap: any = new Map(sortedOrder.map((item, index) => [item.id, index]));
    return items.sort((a, b) => orderMap.get(a.deviceId) - orderMap.get(b.deviceId));
  };

  const triggerSort = (totalDevices, sortById, sortByType) => {
    if (!sortById || !sortByType) {
      return { pagedItems: [], entireItems: [], count: 0, valid: false };
    }
    if (!totalDevices || (totalDevices && totalDevices.length == 0)) {
      return { pagedItems: [], entireItems: [], count: 0, valid: false };
    }

    const newSortedItems = sort(sortById, sortByType, totalDevices);
    const newPagedItems = recalculatePage(newSortedItems, currentPage);

    return {
      pagedItems: newPagedItems,
      entireItems: newSortedItems,
      count: newSortedItems.length,
      valid: true,
    };
  };

  return { triggerSort };
};
