import React from 'react';
import { getStatus, refinedDate } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  getAppNames,
  getGroupsText,
  getStatusText,
  getAssessmentText,
  capitalizeFirstLetter,
  getConnectionStateText,
  getComplianceText,
  getPolicyName,
} from 'src/utils/textConverterMethods';
import {
  TableStatusRenderer,
  TableEllipsisTooltip,
  TableAssessmentRenderer,
  TableConnectivityRenderer,
  TableSerialNumberRenderer,
  TablePolicyComplianceRenderer,
} from 'src/components/atom';
import { dateFormat } from 'src/utils/constants';

const TableRenderers = () => {
  const { setBreadcrumb, customRelativePath } = useShellRootContext();
  const { devicesState } = useStoreState();
  const { searchItem } = devicesState;

  // Renderer Component should contain "value" props.
  const text = (id, value) => (
    <TableEllipsisTooltip id={id} value={value} searchItem={searchItem} />
  );

  /* NOTE: value must be "string" type in order to be searched*/
  const getTableRenderers = (devicesData) => {
    return devicesData?.map((device) => ({
      ...device,
      objectUniqueKey: device.deviceId,
      //serialNumber: text('serialNumber', device.identity.serialNumber),
      serialNumber: (
        <TableSerialNumberRenderer
          value={device.identity.serialNumber}
          deviceId={device.deviceId}
          addedDate={device.timestamp.addedDate}
          setBreadcrumb={setBreadcrumb}
          textComponent={text('serialNumber', device.identity.serialNumber)}
          customRelativePath={customRelativePath}
        />
      ),
      modelName: text('modelName', device.identity.makeAndModel.name),
      // modelName: (
      //   <TableSerialNumberRenderer
      //     value={device.identity.makeAndModel.name}
      //     textComponent={text('modelName', device.identity.makeAndModel.name)}
      //     deviceId={device.deviceId}
      //     addedDate={device.timestamp.firstRegistrationTime}
      //     setBreadcrumb={setBreadcrumb}
      //     customRelativePath={customRelativePath}
      //   />
      // ),
      apps: text('app-renderer', getAppNames(device.software.apps)),
      status: (
        <TableStatusRenderer
          value={getStatus(device.status)}
          textComponent={text(
            'status',
            capitalizeFirstLetter(getStatusText(getStatus(device.status))),
          )}
        />
      ),
      connectionState: (
        <TableConnectivityRenderer
          value={getConnectionStateText(device.connectivity)}
          state={device.connectivity}
          textComponent={text('connectionState', getConnectionStateText(device.connectivity))}
        />
      ),
      connectivityTypes: text('connectivityTypes', device.connTypes),
      addedDate: text('addedDate', device.timestamp.addedDate),
      assessment: (
        <TableAssessmentRenderer
          type={device.solutionMetadata?.[0]?.result}
          value={getAssessmentText(device.solutionMetadata?.[0]?.result)}
          textComponent={text(
            'assessment',
            getAssessmentText(device.solutionMetadata?.[0]?.result),
          )}
        />
      ),
      assetNumber: text('assetNumber', device.identity.assetNumber),
      firmwareVersion: text('firmwareVersion', device.identity.firmwareVersion),
      group: text('group', getGroupsText(device.groups)),
      //hostname: device.identity.serialNumber,
      lastSyncTime: text('lastSyncTime', device.lastUpdatedAt),
      location: text('location', device.identity.location),
      policies: text('policies', getPolicyName(device.complianceList?.policies)),
      policyCompliance: (
        <TablePolicyComplianceRenderer
          value={getComplianceText(device.complianceList?.status, device.complianceList?.policies)}
          policies={device.complianceList?.policies}
          status={device.complianceList?.status}
          textComponent={text(
            'policyCompliance',
            getComplianceText(device.complianceList?.status, device.complianceList?.policies),
          )}
        />
      ),
      policyLastRun: text(
        'policyLastRun',
        device.complianceList?.policies?.length > 0
          ? refinedDate(device.complianceList?.lastRunAt, dateFormat.policy_lastRun)
          : '--',
      ),
      deviceName: text('deviceName', device.identity.friendlyName),
      wiredIPv4: text('wiredIPv4', device.network.adapters[0].ipv4.address.ip),
      wiredIPv6: text('wiredIPv6', device.network.adapters[0].ipv6.address.ip),
      wiredMAC: text('wiredMAC', device.network.adapters[0].macAddress.replaceAll(':', '')),
      wiredHostname: text('wiredHostname', device.network.adapters[0].hostname),
      wifiIPv4: text('wifiIPv4', device.network.adapters[1].ipv4.address.ip),
      wifiIPv6: text('wifiIPv6', device.network.adapters[1].ipv6.address.ip),
      wifiMAC: text('wifiMAC', device.network.adapters[1].macAddress.replaceAll(':', '')),
      wifiHostname: text('wifiHostname', device.network.adapters[1].hostname),
      wifiDirectIPv4: text('wifiDirectIPv4', device.network.adapters[2].ipv4.address.ip),
      wifiDirectIPv6: text('wifiDirectIPv6', device.network.adapters[2].ipv6.address.ip),
      wifiDirectMAC: text(
        'wifiDirectMAC',
        device.network.adapters[2].macAddress.replaceAll(':', ''),
      ),
      wifiDirectHostname: text('wifiDirectHostname', device.network.adapters[2].hostname),
      productNumber: device.identity.makeAndModel.number,
      deviceId: device.deviceId,
      accountId: device.ownership.accountId,
      solutions: device.solutions,
      manufacturer: text('manufacturer', device.identity.makeAndModel.manufacturer),
    }));
  };

  return {
    getTableRenderers,
  };
};

export default TableRenderers;
