import styled, { css } from 'styled-components';
import React from 'react';
import TableExportList from './TableExportList';
import TableSearchList from './TableSearchList';
import { Header } from '@veneer/core';
//import { TableFilter } from 'src/components/molecule';

type TTableHeaderProps = {
  loading?: boolean;
  disable?: boolean;
  showExportAll?: boolean;
  selectedItems?: any;
  columnConfig?;
  columnContents?;
  handleFilterOption?;
  selectedFilter?;
};

const TableHeader = (props: TTableHeaderProps) => {
  return (
    <Wrapper loading={props.loading}>
      <Header
        position="relative"
        data-testid="table-header"
        customStyle={{ border: 'none', background: 'transparent' }}
        leadingArea={
          <LeadingArea>
            <TableSearchList disabled={props.disable} />
            {/*<TableFilter*/}
            {/*  handleFilterOption={props.handleFilterOption}*/}
            {/*  selectedFilter={props.selectedFilter}*/}
            {/*/>*/}
          </LeadingArea>
        }
        trailingArea={
          props.showExportAll && (
            <TableExportList
              disabled={props.loading || props.disable}
              columnConfig={props.columnConfig}
              columnContents={props.columnContents}
            />
          )
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    margin-right: 6px;
  }
  ${(props) => {
    if (props.loading) {
      return css`
        margin-right: 30px;
      `;
    }
  }}
`;

const LeadingArea = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export default TableHeader;
