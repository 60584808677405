import styled from 'styled-components';
import React from 'react';
import {
  Button,
  IconCheckmarkCircle,
  IconInfo,
  IconMinusCircle,
  IconWarningAlt,
} from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableStatusRendererProps = {
  value: string;
  textComponent: React.ComponentElement<any, any>;
};

const TableStatusRenderer = (props: TTableStatusRendererProps) => {
  const { isWex } = useShellRootContext();
  const wexIcon = {
    UNKNOWN: {
      leadingIcon: (
        <IconInfo filled={true} customStyle={{ color: '#ffffff', width: '14px !important' }} />
      ),
      width: '92px',
    },
    ERROR: {
      leadingIcon: (
        <IconMinusCircle
          filled={true}
          customStyle={{ color: '#ffffff', width: '14px !important' }}
        />
      ),
      colorScheme: 'negative',
      width: '70px',
    },
    WARNING: {
      leadingIcon: <IconWarningAlt filled={true} customStyle={{ width: '12px !important' }} />,
      colorScheme: 'warning',
      width: '88px',
    },
    READY: {
      leadingIcon: <IconCheckmarkCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'positive',
      width: '78px',
    },
  };

  const getStatusIcon = () => {
    if (isWex) {
      return (
        <Button
          colorScheme={wexIcon[props.value].colorScheme}
          leadingIcon={wexIcon[props.value].leadingIcon}
          customStyle={{
            minWidth: wexIcon[props.value].width,
            width: wexIcon[props.value].width,
            height: '24px',
            maxWidth: '256px',
            borderRadius: '4px',
            gap: '4px',
            padding: '4px 12px 4px 8px',
            pointerEvents: 'none',
            ...(props.value === 'UNKNOWN' && { backgroundColor: '#666666' }),
          }}
        >
          <WXText>{props.textComponent}</WXText>
        </Button>
      );
    }

    switch (props.value) {
      case 'UNKNOWN':
        return <IconInfo color="gray6" filled={true} size={20} />;
      case 'ERROR':
        return <IconMinusCircle color="red7" filled={true} size={20} />;
      case 'WARNING':
        return <IconWarningAlt color="darkOrange6" filled={true} size={20} />;
      case 'READY':
        return <IconCheckmarkCircle color="hunterGreen7" filled={true} size={20} />;
    }
  };

  return (
    <Wrapper data-testid={'status-renderer'}>
      {getStatusIcon()}
      {!isWex && <StatusText>{props.textComponent}</StatusText>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled.div`
  margin-left: 5px;
`;

const WXText = styled.div`
  span {
    font-family: Forma DJR UI !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: center !important;
  }
`;

export default TableStatusRenderer;
