import styled, { css } from 'styled-components';
import React from 'react';
import TableHeader from 'src/components/molecule/TableHeader';
import { SideBar, Table } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const InitialLoading = () => {
  const { isFromApps } = useShellRootContext();
  const column = (id: number) => {
    return {
      id: id,
      label: '',
      width: 150,
      search: false,
      required: false,
      sortable: false,
    };
  };

  const tableColumns: Array<any> = [column(1), column(2), column(3)];

  return (
    <Wrapper>
      {!isFromApps && (
        <StyledSidebar
          data-testid="initial-loading-sidebar"
          behavior="persistent"
          position="start"
          collapsable
        />
      )}

      <StyledTable
        data-testid="initial-loading-table"
        loading={true}
        actionArea={<TableHeader loading={true} disable={true} />}
        columns={tableColumns}
        loadingDataLength={8}
        component={'table'}
        isApps={isFromApps}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledSidebar = styled(SideBar)`
  pointer-events: none;
`;

const StyledTable = styled(Table)`
  width: 100%;
  padding: ${({ isApps }) => (!isApps ? '24px' : '0')};
  overflow-x: hidden;

  ${({ isApps }) =>
    isApps &&
    css`
      height: 100%;
      width: 101%;
    `}
`;

export default InitialLoading;
