import React from 'react';
import { IconCheckmarkCircle, IconInfo, IconXCircle } from '@veneer/core';
import { ComplianceType } from 'src/utils/constants';
import styled from 'styled-components';

type TTablePolicyComplianceRendererProps = {
  value: string;
  policies: any;
  status: string;
  textComponent: React.ComponentElement<any, any>;
};

const TablePolicyComplianceRenderer = (props: TTablePolicyComplianceRendererProps) => {
  const complianceIcon = (status) => {
    switch (status) {
      case ComplianceType.Compliant:
        return (
          <IconCheckmarkCircle
            data-testid={'compliance-icon'}
            color={'green7'}
            size={20}
            filled
            customStyle={{ flexShrink: 0 }}
          />
        );
      case ComplianceType.NonCompliant:
        return (
          <IconXCircle
            data-testid={'compliance-icon'}
            color={'red7'}
            filled
            size={20}
            customStyle={{ flexShrink: 0 }}
          />
        );
      default:
        // ComplianceType.Unknown
        return (
          <IconInfo
            data-testid={'compliance-icon'}
            color={'gray6'}
            size={20}
            filled
            customStyle={{ flexShrink: 0 }}
          />
        );
    }
  };

  return (
    <Wrapper className={'compliance-type'}>
      {complianceIcon(props.status)}
      <ComplianceText className={'compliance-name'}>{props.textComponent}</ComplianceText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ComplianceText = styled.div`
  margin-left: 5px;
`;

export default TablePolicyComplianceRenderer;
