import tokens from '@veneer/primitives';

const color = tokens.color;
const DefaultColor = {
  light: {
    foreground: {
      default: color.gray12, //'#212121',
      medium: color.gray9, //'#404040',
      light: color.gray7, //'#666666',
      disabled: color.gray5, //'#a3a3a3',
    },
    background: {
      default: color.gray0, //'#fafafa',
      container: color.white,
      disabled: color.gray2, //'#e8e8e8',
    },
    stroke: {
      default: color.gray3, //'#d9d9d9',
    },
    effects: {
      glasswall: 'rgba(33,33,33,0.5)',
      dropShadow: 'rgba(33,33,33,0.2)',
    },
    spotlight: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: color.white,
        hover: 'rgba(255,255,255,0.8)',
        active: 'rgba(255,255,255,0.6)',
      },
      background: {
        default: color.gray12, //'#212121',
        hover: 'rgba(255,255,255,0.1)',
        active: 'rgba(255,255,255,0.2)',
      },
    },
    primary: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#0073a8',
        hover: '#015985',
        active: '#02476b',
      },
      background: {
        default: '#e6f0f5',
        hover: '#ceeffe',
        active: '#b7e8fe',
      },
    },
    neutral: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: color.gray7, //'#666666',
        hover: color.gray8, //'#545454',
        active: color.gray9, //'#404040',
      },
      background: {
        default: '#f1f1f1',
        hover: 'rgba(33,33,33,0.1)',
        active: 'rgba(33,33,33,0.2)',
      },
    },
    positive: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#00834b',
        hover: '#006c3e',
        active: '#005a35',
      },
      background: {
        default: '#d5f5e7',
        hover: '#ccffe8',
        active: '#a3ffd6',
      },
    },
    warning: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ff8900',
        hover: '#e07200',
        active: '#b2600c',
      },
      background: {
        default: '#fcebdc',
        hover: '#ffdbb2',
        active: '#ffc98c',
      },
    },
    negative: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#cc2c00',
        hover: '#9f2301',
        active: '#802208',
      },
      background: {
        default: '#faeceb',
        hover: '#ffdbd9',
        active: '#ffc1bd',
      },
    },
    caution: {
      foreground: {
        default: '#5c4e00',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ffe137',
        hover: '#f9d300',
        active: '#e5c300',
      },
      background: {
        default: '#f7f2cb',
        hover: '#fff29e',
        active: '#ffe970',
      },
    },
    help: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#187585',
        hover: '#176673',
        active: '#16555f',
      },
      background: {
        default: '#d7f4f7',
        hover: '#d4fbff',
        active: '#bcf7ff',
      },
    },
  },
  dark: {
    foreground: {
      default: color.gray12, //'#212121',
      medium: color.gray9, //'#404040',
      light: color.gray7, //'#666666',
      disabled: color.gray5, //'#a3a3a3',
    },
    background: {
      default: color.gray0, //'#fafafa',
      container: color.white,
      disabled: color.gray2, //'#e8e8e8',
    },
    stroke: {
      default: color.gray3, //'#d9d9d9',
    },
    effects: {
      glasswall: 'rgba(33,33,33,0.5)',
      dropShadow: 'rgba(33,33,33,0.2)',
    },
    spotlight: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: color.white,
        hover: 'rgba(255,255,255,0.8)',
        active: 'rgba(255,255,255,0.6)',
      },
      background: {
        default: color.gray12, //'#212121',
        hover: 'rgba(255,255,255,0.1)',
        active: 'rgba(255,255,255,0.2)',
      },
    },
    primary: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#0073a8',
        hover: '#015985',
        active: '#02476b',
      },
      background: {
        default: '#e6f0f5',
        hover: '#ceeffe',
        active: '#b7e8fe',
      },
    },
    neutral: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: color.gray7, //'#666666',
        hover: color.gray8, //'#545454',
        active: color.gray9, //'#404040',
      },
      background: {
        default: '#f1f1f1',
        hover: 'rgba(33,33,33,0.1)',
        active: 'rgba(33,33,33,0.2)',
      },
    },
    positive: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#00834b',
        hover: '#006c3e',
        active: '#005a35',
      },
      background: {
        default: '#d5f5e7',
        hover: '#ccffe8',
        active: '#a3ffd6',
      },
    },
    warning: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ff8900',
        hover: '#e07200',
        active: '#b2600c',
      },
      background: {
        default: '#fcebdc',
        hover: '#ffdbb2',
        active: '#ffc98c',
      },
    },
    negative: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#cc2c00',
        hover: '#9f2301',
        active: '#802208',
      },
      background: {
        default: '#faeceb',
        hover: '#ffdbd9',
        active: '#ffc1bd',
      },
    },
    caution: {
      foreground: {
        default: '#5c4e00',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ffe137',
        hover: '#f9d300',
        active: '#e5c300',
      },
      background: {
        default: '#f7f2cb',
        hover: '#fff29e',
        active: '#ffe970',
      },
    },
    help: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#187585',
        hover: '#176673',
        active: '#16555f',
      },
      background: {
        default: '#d7f4f7',
        hover: '#d4fbff',
        active: '#bcf7ff',
      },
    },
  },
  contrast: {
    foreground: {
      default: color.white,
      medium: color.white,
      light: color.white,
      disabled: '#adadad',
    },
    background: {
      default: '#16141d',
      container: '#16141d',
      disabled: color.gray8, //'#545454',
    },
    stroke: {
      default: '#adadad',
    },
    effects: {
      glasswall: '#16141d',
      dropShadow: 'rgba(22,20,29,0)',
    },
    spotlight: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#fff700',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: color.white,
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    primary: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#fff700',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    neutral: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#fff700',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    positive: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#00d072',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    warning: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#ff8900',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    negative: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#ff6445',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    caution: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#ffe137',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
    help: {
      foreground: {
        default: '#16141d',
        hover: '#16141d',
        active: '#16141d',
      },
      base: {
        default: '#2ac5e0',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
      background: {
        default: '#16141d',
        hover: '#fe6aff',
        active: '#00d3ff',
      },
    },
  },
};
export default DefaultColor;
