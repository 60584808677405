import styled from 'styled-components';
import React, { useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { collectionAPI } from 'src/api/collection';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Button, IconWarningAlt, Modal } from '@veneer/core';
import { ModalTitle, ModalFooter, ModalContent, ModalTitleWrapper } from 'src/styles/global';

type TDeleteGroupModalProps = {
  selectedGroupsIdList: any[];
  showDeleteGroupModal: boolean;
  allDevicesGroupUuid: string;
  setShowDeleteGroupModal: (e) => void;
  setSelectedGroupsIdList: (e) => void;
  setActionCount: (e) => void;
  refreshEditGroupModalPage: () => void;
};

const DeleteGroupModal = (props: TDeleteGroupModalProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const { useToast, isWex } = useShellRootContext();
  const { deleteCollectionByIds } = collectionAPI();

  const handleDeleteGroupModal = async () => {
    const { error } = await deleteCollectionByIds(props.selectedGroupsIdList);

    if (error) {
      useToast.addToast({
        id: 'deleteGroupModalFail',
        type: 'negative',
        text: t('group.delete_group_fail_msg', {
          groupCount: '' + props.selectedGroupsIdList.length,
        }),
      });
    } else {
      useToast.addToast({
        id: 'deleteGroupModalSuccess',
        type: 'positive',
        text: t('group.delete_group_success_msg', {
          groupCount: '' + props.selectedGroupsIdList.length,
        }),
      });
    }
  };

  return (
    <Modal
      show={props.showDeleteGroupModal}
      title=""
      maxWidth="612px"
      alignFooter={isWex ? undefined : 'end'}
      data-testid="delete-group-modal"
      footer={
        <ModalFooter>
          <Button
            data-testid="delete-group-modal-delete-button"
            appearance="primary"
            colorScheme="negative"
            onClick={() => {
              setIsFetching(true);
              props.setActionCount((prevCount: number) => {
                return prevCount + 1;
              });
              handleDeleteGroupModal().finally(() => {
                setIsFetching(false);
                props.setShowDeleteGroupModal(false);
                props.refreshEditGroupModalPage();
              });
            }}
            loading={isFetching}
          >
            {t('group.delete')}
          </Button>

          <Button
            data-testid="delete-group-modal-cancel-button"
            appearance="secondary"
            onClick={() => props.setShowDeleteGroupModal(false)}
            disabled={isFetching}
          >
            {t('group.cancel')}
          </Button>
        </ModalFooter>
      }
    >
      <ModalTitleWrapper>
        <IconWarningAlt filled color="darkOrange6" size={36} />
        <ModalTitle data-testid="group-modal-delete-title">{t('group.delete')}</ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>{t('group.delete_description')}</ModalContent>

      <SubContent>{t('group.delete_sub_description')}</SubContent>
    </Modal>
  );
};

const SubContent = styled.div`
  font-weight: bold;
  padding-top: 16px;
`;

export default DeleteGroupModal;
