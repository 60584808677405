import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { TableContextualFooter } from 'src/components/molecule';
import { DeviceTable, MainGroup } from 'src/components/organism';
import { ResizeObserver } from 'resize-observer';
import { MainGroupSideBar } from 'src/components/atom';
import { DeviceTableRefreshType } from 'src/utils/constants';

type TDeviceMainTemplateProps = {
  errorGroup: boolean;
  errorTable: boolean;
  loadingTable: boolean;
  columnConfig: any;
  setShowEditGroupModal: (boolean) => void;
};

export const DeviceMainTemplate = (props: TDeviceMainTemplateProps) => {
  const { devicesState, devicesRnPmState, devicesGroupState } = useStoreState();
  const { mainGroupData } = devicesGroupState;
  const { contentsDevicesReadRnPm, reportsOwnerRnPm, contentsDevicesUpdateRnPm } = devicesRnPmState;
  const {
    showDevicesGroupView,
    showDevicesGroupCUD,
    showDevicesTableCheckbox,
    showDevicesTableActionArea,
    isWex,
  } = useShellRootContext();
  const {
    isDevicesTab,
    tableData,
    selectedItems,
    cfgApiRequestOptions,
    deviceTableRefresh,
    totalDeviceCountInGroup,
    sortById,
    sortByType,
  } = devicesState;
  const { tableHandler } = useTableHandler();
  const [bodyWidth, setBodyWidth] = useState(0);
  const [groupScrollBarWidth, setGroupScrollBarWidth] = useState(0);

  // table config enable variables
  const showCheckbox = showDevicesTableCheckbox ? 'multiSelection' : '';
  const showHeaderActionArea = showDevicesTableActionArea;
  const showExportAll = reportsOwnerRnPm;
  const showContextualFooterCondition =
    contentsDevicesUpdateRnPm && showDevicesGroupCUD && selectedItems.length > 0;
  const contentId = isDevicesTab ? '' : 'device-main-content';
  const onResize = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width } = entry.contentRect;
      setBodyWidth(width);
    }
  });

  useEffect(() => {
    onResize.observe(document.querySelector('div[id="ecp-devices-main-template-body"]'));
  }, []);

  const memorizedGroup = useMemo(() => {
    return (
      <MainGroupSideBar groupScrollBarWidth={groupScrollBarWidth}>
        <GroupContainer groupScrollBarWidth={groupScrollBarWidth}>
          <MainGroup
            {...{
              mainGroupData: mainGroupData,
              error: props.errorGroup,
              isFetchingForTable: props.loadingTable,
              cfgApiRequestOptions: cfgApiRequestOptions,
              setShowEditGroupModal: props.setShowEditGroupModal,
              setGroupScrollBarWidth: setGroupScrollBarWidth,
            }}
            data-testid="device-main-group-tree"
          />
        </GroupContainer>
      </MainGroupSideBar>
    );
  }, [mainGroupData, groupScrollBarWidth]);

  const memoizedTable = useMemo(() => {
    return (
      <DeviceTable
        {...{
          error: props.errorTable,
          loading: deviceTableRefresh != DeviceTableRefreshType.NONE,
          tableData: tableData,
          deviceCount: totalDeviceCountInGroup,
          showCheckbox,
          showHeaderActionArea,
          showExportAll,
          columnConfig: props.columnConfig,
          columnReorder: totalDeviceCountInGroup != 0,
        }}
      />
    );
  }, [tableData, sortById, sortByType, deviceTableRefresh]);

  return (
    <Content id={contentId} isWex={isWex}>
      {/*{isWex && <GlobalHeader />}*/}
      <Body id={'ecp-devices-main-template-body'}>
        {showDevicesGroupView && memorizedGroup}

        {contentsDevicesReadRnPm && memoizedTable}
        {showContextualFooterCondition && (
          <TableContextualFooter
            selectedItems={selectedItems}
            handleFooterCancel={tableHandler.handleFooterCancel}
            bodyWidth={bodyWidth}
          />
        )}
      </Body>
    </Content>
  );
};

const Content = styled.div``;

const GroupContainer = styled.div`
  position: relative;

  //upper area(250px) + border(2px)+ padding(40px) + scrollbar
  ${(props) => {
    return `width: calc(250px + 2px + 40px + ${props.groupScrollBarWidth}px);`;
  }}

  height: 100%;
  margin-right: 24px;
  display: flex;

  @media (max-width: 768px) {
    min-width: unset;
    max-width: unset;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export default DeviceMainTemplate;
