import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeviceAppConfigTemplate } from 'src/components/template';
import useTableColumn from 'src/hooks/useTableColumn';
import { setTableColumns } from 'src/utils/commonMethods';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { useStoreState } from 'src/store/useStoreState';
import {
  storeDeviceTableRefresh,
  storeTableData,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useDispatch } from 'react-redux';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useSearch } from 'src/hooks/useSearch';
import { InitialLoading } from 'src/components/molecule';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { DeviceTableRefreshType, fullListOption } from 'src/utils/constants';
import { fetchAllWithLoop } from 'src/utils/groupMethods';
import { fleetProxyAPI } from 'src/api/fleetProxy';

const DeviceAppConfigPage = () => {
  const { devicesState, devicesRnPmState } = useStoreState();
  const { deviceTableRefresh, searchItem, currentPage, sortClicked, cfgApiRequestOptions } =
    devicesState;

  const { getDevices } = deviceCacheAPI();
  const { getProxiesByTenant } = fleetProxyAPI();
  const dispatch = useDispatch();

  const { appName } = useShellRootContext();
  const tableId = 'apps-table';
  const initialFetch = useRef(true);
  const entireDeviceCount = useRef();
  const fleetProxyList = useRef([]);
  const enableRnPm =
    devicesRnPmState.contentsDevicesCountRnPm && devicesRnPmState.contentsCollectionsReadRnPm;

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  /* This is used when Search, Sort is activated */
  const [entireDataCache, setEntireDataCache] = useState([]);
  const [isEntireDataCached, setIsEntireDataCached] = useState(false);

  const { tableHandler } = useTableHandler();
  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(columnConfig);

  const fetchTable = () => {
    setIsFetching(true);
    apiGetTableResponse()
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices);
        if (isError) {
          setError(isError);
        } else {
          setError(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
        setIsFetching(false);
      });
  };

  useEffect(() => {
    setIsEntireDataCached(false);
    setEntireDataCache([]);
    fetchForInitialTime();
  }, []);

  useEffect(() => {
    if (entireDeviceCount.current) {
      fetchTable();
    }
  }, [entireDeviceCount.current]);

  useEffect(() => {
    if (deviceTableRefresh == DeviceTableRefreshType.NONE) {
      return;
    }

    if (isEntireDataCached && !!cfgApiRequestOptions.search) {
      setTableColumns(tableId, columnConfig);
      handlePageData(entireDataCache);
      dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
    }

    fetchTable();
  }, [deviceTableRefresh]);

  /*  This is for Pagination
   * when table data is changed without calling API, and reuse saved list
   * search/sort is active, and page changes
   * */
  useEffect(() => {
    if (searchItem == '') {
      return;
    }

    if (isEntireDataCached) {
      handlePageData(entireDataCache);
    }
  }, [currentPage]);

  useEffect(() => {
    if (initialFetch.current && searchItem == '') {
      return;
    }

    if (searchItem == '' || !!cfgApiRequestOptions.search) {
      setEntireDataCache([]);
      setIsEntireDataCached(false);
      dispatch(storeTotalDeviceCountInGroup(entireDeviceCount.current));
    }

    dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.REFRESH_WITHOUT_API_CALL));
  }, [searchItem]);

  const fetchForInitialTime = async () => {
    setIsFetching(true);
    await apiGetFleetProxy();
    apiGetTableResponse()
      .then(({ devices }) => {
        initialFetch.current = false;
        entireDeviceCount.current = devices.length;
        dispatch(storeTotalDeviceCountInGroup(devices.length)); // to get total device count
      })
      .finally(() => {});
  };

  const getRequestParams = (params) => {
    let returnParams = params;
    if (params.search && params.search.length > 0) {
      returnParams = { ...params, ...fullListOption };
    }
    if (cfgApiRequestOptions.search != null && cfgApiRequestOptions.search.length > 0) {
      returnParams = { ...cfgApiRequestOptions, ...fullListOption };
    }
    return returnParams;
  };

  const fetchAllDevices = async (_reqParam) => {
    let _response = [];
    const setAllDevices = (_data) => {
      _response = _data;
    };
    const getDevicesCacheAPI = async (_Id, _offset, _limit) => {
      return await getDevices({
        ..._reqParam,
        offset: _offset,
        limit: _limit,
      });
    };

    const devices = await fetchAllWithLoop(
      getDevicesCacheAPI,
      setAllDevices,
      setError,
      '',
      'deviceCache',
    );
    return { response: devices };
  };
  const apiGetTableResponse = async () => {
    console.log(`[app-overview][devices] apiGet Table Response`);

    const reqParam = getRequestParams(cfgApiRequestOptions);

    const fetch = async () => {
      if (initialFetch.current) {
        console.log('[apps] initial fetch');
        return await fetchAllDevices(reqParam);
      }

      if (searchItem == '' && !isEntireDataCached) {
        console.log('[apps] no search, return page data');
        return await getDevices(reqParam);
      }

      if (searchItem != '') {
        return await fetchAllDevices(reqParam);
      }

      if (isEntireDataCached && entireDataCache.length > 0) {
        console.log('[apps] no fetch, return cached data ');
        return { response: { entireDataCache } };
      }
    };

    const { response, error } = await fetch();

    if (error != undefined) {
      return { devices: [], isError: true };
    }

    return {
      devices: response,
      isError: false,
    };
  };

  const apiGetFleetProxy = async () => {
    console.log('[devices] apiGetFleetProxy');
    const setFleetProxyConnectionState = (response) => {
      fleetProxyList.current = response;
    };

    return await fetchAllWithLoop(
      getProxiesByTenant,
      setFleetProxyConnectionState,
      null,
      '',
      'fleetproxy',
    );
  };

  const handlePageData = (data) => {
    let items;
    const count = data.length;

    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    items = items?.map((item) => ({
      ...item,
      connectivity: item.connTypes?.includes('Proxy')
        ? fleetProxyList.current
            .find((comp) => item.fleetProxyId.includes(comp.id))
            ?.connectionState.status.toLowerCase() ?? 'online'
        : item.status?.connectionState,
    }));

    if (searchItem) {
      const { pagedItems, entireItems, count, valid } = triggerSearch(
        items,
        searchItem,
        currentPage,
      );
      if (valid) {
        items = pagedItems;
        dispatch(storeTotalDeviceCountInGroup(count));
        setIsEntireDataCached(true);
        setEntireDataCache(entireItems);
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
  };

  const enableRender = !isFetching && entireDeviceCount.current && enableRnPm;

  const memoRenderComponent = useMemo(() => {
    if (enableRender) {
      console.log('[devices] render <DeviceforAppTableTemplate />');
      return (
        <DeviceAppConfigTemplate error={error} loading={isFetching} columnConfig={columnConfig} />
      );
    } else {
      return <></>;
    }
  }, [enableRender]);

  return <>{isFetching ? <InitialLoading /> : <>{memoRenderComponent}</>}</>;
};

export default DeviceAppConfigPage;
