import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.devices.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeIsDevicesTab = (_payload) => {
  return {
    type: store.devices.IS_DEVICES_TAB,
    payload: _payload,
  };
};

export const storeIsFromDetails: any = (_payload) => {
  return {
    type: store.devices.IS_NAVIGATED_FROM_DETAILS,
    payload: _payload,
  };
};

export const storeResetDevicesReducer = () => {
  return {
    type: store.devices.RESET_DEVICES_REDUCER,
  };
};

export const storeIsFromUnassigned = (_payload) => {
  return {
    type: store.devices.IS_FROM_UNASSIGNED,
    payload: _payload,
  };
};

export const storeDeviceTableRefresh = (_payload) => {
  return {
    type: store.devices.DEVICE_TABLE_REFRESH,
    payload: _payload,
  };
};

export const storeInitialColumns = (_payload) => {
  return {
    type: store.devices.STORE_INITIAL_COLUMNS,
    payload: _payload,
  };
};

export const storeTableData = (_payload) => {
  return {
    type: store.devices.STORE_TABLE_DATA,
    payload: _payload,
  };
};

export const storeTotalDeviceCountInGroup = (_payload) => {
  return {
    type: store.devices.STORE_TOTAL_DEVICE_COUNT_IN_GROUP,
    payload: _payload,
  };
};

export const storeTableFilter = (_payload) => {
  return {
    type: store.devices.STORE_TABLE_FILTER,
    payload: _payload,
  };
};

export const storeSortById = (_payload) => {
  return {
    type: store.devices.STORE_TABLE_SORT_ID,
    payload: _payload,
  };
};

export const storeSortByType = (_payload) => {
  return {
    type: store.devices.STORE_TABLE_SORT_TYPE,
    payload: _payload,
  };
};

export const storeTableSortClicked = (_payload) => {
  return {
    type: store.devices.STORE_TABLE_SORT_CLICKED,
    payload: _payload,
  };
};

export const storeSelectedItems = (_payload) => {
  return {
    type: store.devices.STORE_SELECTED_ITEMS,
    payload: _payload,
  };
};

export const storeColumnContents = (_payload) => {
  return {
    type: store.devices.STORE_COLUMN_CONTENTS,
    payload: _payload,
  };
};

export const storeSearchItem = (_payload) => {
  return {
    type: store.devices.STORE_SEARCH_ITEM,
    payload: _payload,
  };
};

export const storeCurrentPage = (_payload) => {
  return {
    type: store.devices.STORE_CURRENT_PAGE,
    payload: _payload,
  };
};

export const storePageSize = (_payload) => {
  return {
    type: store.devices.STORE_PAGE_SIZE,
    payload: _payload,
  };
};

export const storeCfgApiRequestOptions = (_payload) => {
  return {
    type: store.devices.STORE_API_REQUEST_OPTIONS,
    payload: _payload,
  };
};
