import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, IconSearch, Search } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { ResponsiveWrapper } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableSearchListProps = {
  disabled: any;
};

const TableSearchList = (props: TTableSearchListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { isFromApps } = useShellRootContext();
  const { tableHandler } = useTableHandler();
  const { devicesGroupState, devicesState } = useStoreState();
  const { mainGroupSelectedName } = devicesGroupState;
  const { searchItem } = devicesState;
  const groupName =
    mainGroupSelectedName == 'All' || mainGroupSelectedName == 'Ungrouped'
      ? t('group.group_' + mainGroupSelectedName.toLowerCase())
      : mainGroupSelectedName;

  useEffect(() => {
    if (searchItem !== '') {
      setSearchValue(searchItem);
    }
  }, [searchItem]);

  const i18nSearch = {
    clear: t('table.clear'),
    showingResult: t('table.showing_result'),
    showingResults: t('table.showing_results'),
  };

  const MobileButton = () => {
    return <Button appearance={'ghost'} leadingIcon={<IconSearch />} />;
  };

  const handleSubmit = (e) => {
    // request search
    if (searchValue.length >= 3) {
      tableHandler.handleSearch(searchValue, isFromApps);
    }
    // refresh table in case of searchValue is empty.
    else if (searchValue === '') {
      tableHandler.handleSearch('', isFromApps);
    }
    e.preventDefault();
  };

  return (
    <ResponsiveWrapper mobile={<MobileButton />}>
      <div id="search-box-section" data-testid="table-search-list">
        <Form onSubmit={handleSubmit} data-testid="search-form">
          <Search
            key={mainGroupSelectedName}
            disabled={props.disabled}
            id="controlled-search"
            data-testid="search-box"
            defaultValue={searchItem}
            placeholder={t('table.search_device', { groupName: groupName })}
            maxLength={20}
            i18n={i18nSearch}
            onChange={(value) => {
              setSearchValue(value);
            }}
            onClear={() => tableHandler.handleSearch('', isFromApps)}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        </Form>
      </div>
    </ResponsiveWrapper>
  );
};

const Form = styled.form`
  width: 272px;
  min-height: 36px;

  button:last-child {
    cursor: default;
  }
  button:last-child:hover {
    background: none;
    color: rgb(84, 84, 84);
  }
`;

export default TableSearchList;
