import styled from 'styled-components';
import React from 'react';

type TTableAssessmentRendererProps = {
  type: string;
  value: string;
  textComponent: React.ComponentElement<any, any>;
};

const TableAssessmentRenderer = (props: TTableAssessmentRendererProps) => {
  return (
    <Wrapper>
      <AssessmentIcon data-testid="assessment-icon" status={props.type} />
      <Text>{props.textComponent}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const AssessmentIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 13px;
  background-color: ${(props) => {
    switch (props.status) {
      // #D41E4F
      case 'highRisk':
        return '#D41E4F';
      // #F35D3E
      case 'mediumRisk':
        return '#F35D3E';
      // #FFCE33
      case 'lowRisk':
        return '#FFCE33';
      // #57AEA4
      case 'passed':
        return '#57AEA4';
      case 'unknown':
        return '#000000';
      // #7300A9
      case 'notAssessed':
      default:
        return '#7300A9';
    }
  }};
  margin-right: 8px;
  display: inline-block;
  border-radius: 100%;
`;

const Text = styled.div`
  margin-left: 19px;
`;

export default TableAssessmentRenderer;
