import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useCallback } from 'react';
import moment from 'moment';
import { t } from 'src/utils/commonMethods';
import { Link } from 'react-router-dom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableModelNameRendererProps = {
  value: string;
  deviceId: string;
  addedDate: any;
  setBreadcrumb: any;
  textComponent: React.ComponentElement<any, any>;
  customRelativePath: any;
};

const TableSerialNumberRenderer = (props: TTableModelNameRendererProps) => {
  const { isWex, isFromApps } = useShellRootContext();

  const renderNewIcon = () => {
    if (props.addedDate == '--') return;
    const currentDate = moment();
    const elapseTime = currentDate.diff(props.addedDate, 'days');
    return elapseTime > 5 || isNaN(elapseTime) ? '' : <NewIcon isWex={isWex} />;
  };

  const updateBreadcrumb = useCallback(() => {
    const getUrl = () => {
      if (isWex) {
        return `${window.location.pathname}/${props.deviceId}`;
      }
      return `${props.customRelativePath}/${props.deviceId}`;
    };

    props.setBreadcrumb({
      text: t('details'),
      url: getUrl(),
      key: 'menu-devices-' + props.deviceId,
    });
  }, []);

  const getCustomPath = () => {
    if (isWex) {
      return `${window.location.pathname}/${props.deviceId}`;
    } else {
      return `/${props.deviceId}`;
    }
  };

  return (
    <Wrapper isWex={isWex}>
      <NewIconContent data-testid="new-icon-content">{renderNewIcon()}</NewIconContent>

      {isFromApps ? (
        <span data-testid={'serial-number-text'}>{props.textComponent}</span>
      ) : (
        <Link
          to={getCustomPath()}
          className="device-link"
          data-testid="device-link"
          id={props.deviceId}
          onClick={updateBreadcrumb}
        >
          {props.textComponent}
        </Link>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: -10px;

  .device-link {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : tokens.color.hpBlue7)};
    position: relative;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: transparent;
    }
  }

  &:hover {
    .device-link::after {
      background-color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : tokens.color.hpBlue7)};
    }
  }
`;

const NewIcon = styled.div`
  margin-right: 5px;
  width: 4px;
  height: 4px;
  background-color: ${(props) => (props.isWex ? '#CC2C00' : '#BE1313')};
  border-radius: 50%;
`;

const NewIconContent = styled.div`
  width: 5px;
  margin-right: 5px;
`;

export default TableSerialNumberRenderer;
