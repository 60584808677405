import useApiClient from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { getProps, isMockUpMode } from 'src/utils/commonMethods';
import { refinedConnectorProxyTenant } from 'src/api/fleetProxy/fleetProxyRefinery';

import getFleetProxyConnectorProxyTenantMock from 'src/mock/fleetProxy/get-fleet-proxy-connector-proxy-tenant.json';
import axios from 'axios';

/**
 * Fleet Proxy API
 */
export const fleetProxyAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.fleetProxy);
  const { demoEnabled, apiPath } = getProps();

  const getProxiesByTenant = async () => {
    console.log('[devices] getProxiesByTenant');
    let response, error;

    if (demoEnabled) {
      const url = apiPath('demo', 'generic', 'printMfeCache', 'printProxies_list');
      await axios.get(url).then((res) => {
        response = refinedConnectorProxyTenant(res.data);
      });
      return { response, error };
    }

    if (isMockUpMode()) {
      response = refinedConnectorProxyTenant(getFleetProxyConnectorProxyTenantMock);
      // await getMockUpData('fleetProxy/get-fleet-proxy-connector-proxy').then((data) => {
      //   response = refinedConnectorProxy(data);
      // });
      return { response, error };
    }

    await client
      .getProxiesByTenant()
      .then((res) => {
        response = refinedConnectorProxyTenant(res.data);
      })
      .catch((err) => {
        error = err;
      });
    return { response, error };
  };

  return {
    getProxiesByTenant,
  };
};
