import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState } from 'react';
import { FilterItem } from 'src/components/atom';
import { Header, IconFunnel, Search, SideBar } from '@veneer/core';

const TableFilter = (props: {
  handleFilterOption: (e) => void;
  selectedFilter: any;
  disabled?: boolean;
}) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const handleShowSideBar = () => {
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);
  };

  const doHaveSelectedFilter = props.selectedFilter?.length > 0;

  const connectivityFilter = ['Online', 'Offline'];

  return (
    <>
      <FilterWrapper
        data-testid="table-filter-entry"
        onClick={() => handleShowSideBar()}
        disabled={props.disabled}
      >
        <IconFunnel
          filled={doHaveSelectedFilter}
          color={props.disabled ? 'colorGray3' : 'colorHpBlue6'}
        />
        <Label>Filter</Label>
        {doHaveSelectedFilter && <>({props.selectedFilter.length})</>}
      </FilterWrapper>

      <SideBar
        id="filter-sidebar"
        behavior="overlay"
        position="end"
        show={showSideBar}
        onClose={handleShowSideBar}
        content={
          <FilterContent>
            <p id="filter-title">Filter</p>
            <p id="filter-subtitle">Select filters to view in the list.</p>
            <Header
              id="filter-search"
              position="relative"
              leadingArea={<Search id="controlled-search" placeholder="Search filters" />}
            />

            <FilterItem
              filterLabel="Connectivity"
              filterItems={connectivityFilter}
              handleFilterOption={props.handleFilterOption}
            />
          </FilterContent>
        }
      />
    </>
  );
};

export default TableFilter;

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => (props.disabled ? '#adadad' : tokens.color.hpBlue6)};
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  padding: 0 20px;
  border-radius: 12px;
  &:hover {
    // colorHpBlue0
    background-color: rgba(235, 249, 255, 0.5);
  }
`;

const Label = styled.span`
  font-family: Forma DJR UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-left: 4px;
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 5px 20px;

  #filter-search > div {
    width: 255px;
  }

  #filter-title {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  #filter-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .margin-top20 {
    margin-top: 20px;
  }

  #create-filter-delete-button {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
`;
