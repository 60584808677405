import { ThemeProvider } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import React, { useEffect, useState } from 'react';
import { getSystem } from 'src/styles/palette_v2';

const CustomThemeProvider = ({ children }) => {
  const { theme, isWex, mode } = useShellRootContext();

  const [themeProviderProps, setThemeProviderProps] = useState({
    ...theme?.getThemeProviderProperties?.(),
    density: 'high',
    shape: 'round',
    mode: theme?.getUserThemeMode?.(),
  });

  /**
   * Fixes outdated WX theme for Veneer v3.105+
   *
   * @param themeProps theme properties
   * @returns corrected theme properties
   */
  function fixTheme(themeProps) {
    let props = themeProps;
    // Newer Veneer expects background container object, not just one color as it was before v3.105
    const light = props.customSemantics?.color?.light?.background?.container;
    if (light && !light.default) {
      // fix light theme if needed
      props = {
        ...props,
        customSemantics: {
          ...props.customSemantics,
          color: {
            ...props.customSemantics.color,
            light: {
              ...props.customSemantics.color.light,
              background: {
                ...props.customSemantics.color.light.background,
                container: {
                  default: '#ffffff',
                  hover: '#f5f5f5',
                  active: '#f0f0f0',
                },
              },
            },
          },
        },
      };
    }
    const dark = props.customSemantics?.color?.dark?.background?.container;
    if (dark && !dark.default) {
      // fix dark theme if needed
      props = {
        ...props,
        customSemantics: {
          ...props.customSemantics,
          color: {
            ...props.customSemantics.color,
            dark: {
              ...props.customSemantics.color.dark,
              background: {
                ...props.customSemantics.color.dark.background,
                container: {
                  default: '#292929',
                  hover: '#303030',
                  active: '#404040',
                },
              },
            },
          },
        },
      };
    }
    return props;
  }

  useEffect(() => {
    if (isWex) {
      setThemeProviderProps({
        ...themeProviderProps,
        density: 'high',
        shape: 'pill',
        mode: mode,
        customSemantics: getSystem(),
      });
    }
  }, [mode]);

  return <ThemeProvider {...fixTheme(themeProviderProps)}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
