import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { getMockUpData, isMockUpMode } from 'src/utils/commonMethods';

export const accountMgtAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.accountMgt);

  const deleteDevice = async (accountId, cloudId) => {
    let response, error;

    await client
      .deleteDevice(accountId, cloudId)
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const getAccount = async () => {
    let response, error;
    console.log('isMockUpMode()', isMockUpMode());
    if (isMockUpMode()) {
      await getMockUpData(`accountMgt/get-account`)
        .then((data) => {
          response = data;
        })
        .catch((err) => {
          console.log('err', err);
        });
      console.log('getAccount', response);

      return { response, error };
    }

    await client
      .getAccount()
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    deleteDevice,
    getAccount,
  };
};
