import React, { useEffect, useState } from 'react';
import styles from 'src/styles/popup.module.scss';
import { t } from 'src/utils/commonMethods';
import { TDownloadStatus } from 'src/types/TDownloadStatus';
import { downloadJsonMethods } from 'src/utils/downloadJsonMethods';
import { Button, IconWarning, IconXCircle, ProgressIndicator } from '@veneer/core';
import styled from 'styled-components';

type TDownloadAppConfigContentProps = {
  status: TDownloadStatus;
  completed?: number;
  deviceCount: number;
  devices: any;
  data?: any;
  handleClose: () => void;
  isPreRender: boolean;
};

const DownloadAppConfigContent = (props: TDownloadAppConfigContentProps) => {
  const [percent, setPercent] = useState(0);
  const headerIconMap = {
    partial_complete: <IconWarning color="gray5" filled={true} size={32} />,
    partial_skipped: <IconWarning color="gray5" filled={true} size={32} />,
    error_unable_to_download: <IconXCircle color="red7" filled={true} size={32} />,
    error_fail_to_collect: <IconXCircle color="red7" filled={true} size={32} />,
  };
  const showProgress = props.status === 'initial' || props.status === 'downloading';

  const footerBtnMap = {
    close: (
      <Button appearance="secondary" onClick={props.handleClose}>
        Close
      </Button>
    ),
    cancel: (
      <Button appearance="ghost" onClick={props.handleClose}>
        Cancel
      </Button>
    ),
  };

  useEffect(() => {
    if (props.completed === props.deviceCount && props.data) {
      downloadJsonMethods(props.data, props.devices);
      props.handleClose();
      return;
    }

    setPercent((props.completed / props.deviceCount) * 100);
  }, [props.completed, props.data]);

  const getHeaderIcon = () => {
    if (props.isPreRender) {
      return (
        <>
          {headerIconMap['partial_complete']}
          {headerIconMap['partial_skipped']}
          {headerIconMap['error_unable_to_download']}
          {headerIconMap['error_fail_to_collect']}
        </>
      );
    }
    return headerIconMap[props.status];
  };
  const getFooterBtn = () => {
    if (props.isPreRender) {
      return (
        <>
          {footerBtnMap.close}
          {footerBtnMap.cancel}
        </>
      );
    }
    return footerBtnMap[showProgress ? 'cancel' : 'close'];
  };

  return (
    <DownLoadAppConfigContentWrapper className={styles.wrapper} isPreRender={props.isPreRender}>
      <div className={styles.content}>
        <div className={styles.header}>
          {(!showProgress || props.isPreRender) && (
            <div className={styles.header__icon_wrapper}>{getHeaderIcon()}</div>
          )}
          <span className={styles.title}>{t(`window.${props.status}.title`)}</span>
        </div>
        <span className={styles.body}>{t(`window.${props.status}.body`)}</span>
        <div className={styles.footer}>
          {(showProgress || props.isPreRender) && (
            <ProgressIndicator appearance="linear" behavior="determinate" value={percent} />
          )}
          <div
            className={
              `${styles.footer__controls} ` +
              (showProgress ? '' : `${styles.footer__controls_left}`)
            }
          >
            {(showProgress || props.isPreRender) && (
              <span>
                {props.completed}/{props.deviceCount} completed
              </span>
            )}
            {getFooterBtn()}
          </div>
        </div>
      </div>
    </DownLoadAppConfigContentWrapper>
  );
};

const DownLoadAppConfigContentWrapper = styled.div`
  ${(props) => {
    if (props.isPreRender) return 'visibility: hidden;';
  }}
`;

export default DownloadAppConfigContent;
