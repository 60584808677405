import { refined, refinedDate } from 'src/utils/commonMethods';

export const refinedConnectorProxy = (data) => {
  return {
    id: refined(data.id),
    tenantId: refined(data.tenantId),
    proxyName: refined(data.proxyName),
    proxyType: refined(data.proxyType),
    softwareVersion: refined(data.softwareVersion),
    hostName: refined(data.hostName),
    devices: refined(data.devices),
    description: refined(data.description),
    proxyState: data.proxyState,
    connectionState: data.connectionState,
    dateAdded: refinedDate(data.dateAdded),
    lastUpdated: refinedDate(data.lastUpdated),
    version: refined(data.version),
  };
};

export const refinedConnectorProxyTenant = (data) => {
  return {
    content:
      data.content?.map((obj) => ({
        id: refined(obj.id),
        tenantId: refined(obj.tenantId),
        proxyName: refined(obj.proxyName),
        proxyType: refined(obj.proxyType),
        softwareVersion: refined(obj.softwareVersion),
        hostName: refined(obj.hostName),
        devices: refined(obj.devices),
        description: refined(obj.description),
        proxyState: obj.proxyState,
        connectionState: obj.connectionState,
        dateAdded: refinedDate(obj.dateAdded),
        lastUpdated: refinedDate(obj.lastUpdated),
        version: refined(obj.version),
      })) ?? [],
  };
};
