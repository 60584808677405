import { SideBar } from '@veneer/core';
import React from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import styled from 'styled-components';
import { useStoreState } from 'src/store/useStoreState';
import { useDispatch } from 'react-redux';
import { storeShowSideBar } from 'src/store/devicesGroup/action';

const MainGroupSideBar = (props) => {
  const { devicesGroupState } = useStoreState();
  const { showSideBar } = devicesGroupState;
  const { isWex } = useShellRootContext();
  const dispatch = useDispatch();
  //upper area(250px) + border(2px) + padding(40px)
  const wxWidth = 250 + 2 + 40;
  //upper area(250px) + border(2px) + padding(40px) + scrollbar
  const ecpWidth = 250 + 2 + 40 + props.groupScrollBarWidth;

  return (
    <SideBarWrapper data-testid={'main-group-side-bar-wrapper'} isWex={isWex}>
      <SideBar
        behavior="persistent"
        position="start"
        collapsable
        show={showSideBar}
        content={props.children}
        onClose={() => dispatch(storeShowSideBar(false))}
        onCollapse={() => dispatch(storeShowSideBar(false))}
        onExpand={() => dispatch(storeShowSideBar(true))}
        size={isWex ? wxWidth : ecpWidth}
      />
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  display: flex;
  float: left;
  ${(props) => {
    if (props.isWex) return 'height: calc(100vh - 152px)';
    //breadcrumb(80px) + header(108px) + tab(48px) + footer(64px) + space between(32px)
    else return 'height: calc(100vh - 236px - 64px - 32px)';
  }}
`;

export default MainGroupSideBar;
