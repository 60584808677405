import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useEffect } from 'react';

export const useSearch = (columnConfig) => {
  const { tableHandler } = useTableHandler();
  const { devicesState } = useStoreState();
  const { cfgApiRequestOptions, searchItem } = devicesState;

  const idName = 'deviceId';
  const searchCols = columnConfig.cfgColumnsList().filter((col) => col.search);

  const getSliceEnd = (option, length) => {
    const totalPageCount = Math.floor(length / option.limit) + 1;
    const currentPage = option.offset;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * option.limit;
    } else {
      return length;
    }
  };

  const recalculatePage = (newFilteredItems, currentPage) => {
    const newPageOption = {
      offset: currentPage - 1,
      limit: cfgApiRequestOptions.limit,
    };
    const lastIndex = getSliceEnd(newPageOption, newFilteredItems.length);
    return newFilteredItems.slice(newPageOption.offset * newPageOption.limit, lastIndex);
  };

  const search = (searchKey, items) => {
    const contents = tableHandler.getColumnContents(items);
    const entries = Object.entries(contents).map(([id, entry]) => {
      const val = searchCols.reduce((r, col) => {
        r[col.id] = entry[col.id];
        return r;
      }, {});
      val['id'] = id;
      return val;
    });
    const result = entries.filter((item) => hasSearchKey(searchKey, item)).map((item) => item.id);
    return items.filter((item) => result.includes(item[idName]));
  };

  const triggerSearch = (totalDevices, searchKey, currentPage) => {
    const key = searchKey?.toLowerCase() || '';

    if (!key || key == '') {
      return { pagedItems: [], count: 0, valid: false };
    }

    if (!totalDevices || (totalDevices && totalDevices.length == 0)) {
      return { pagedItems: [], count: 0, valid: false };
    }

    const newFilteredItems = search(key, totalDevices);
    const newPagedItems = recalculatePage(newFilteredItems, currentPage);

    return {
      pagedItems: newPagedItems,
      entireItems: newFilteredItems,
      count: newFilteredItems.length,
      valid: true,
    };
  };

  const hasSearchKey = (searchKey, item) => {
    for (const [columnName, value] of Object.entries(item)) {
      if (columnName === 'id') continue;
      if (value !== undefined && handleSearch(columnName, value, searchKey)) {
        return true;
      }
    }
    return false;
  };

  const handleSearch = (columnName, value, searchKey) => {
    if (value === null) return false;
    return value.toLowerCase().includes(searchKey);
  };

  return { triggerSearch };
};
