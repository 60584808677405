import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Button, IconCheckmarkCircle, IconXCircle } from '@veneer/core';

type TTableConnectivityRendererProps = {
  value: string;
  state: string;
  textComponent: React.ComponentElement<any, any>;
};

const TableConnectivityRenderer = (props: TTableConnectivityRendererProps) => {
  const { isWex } = useShellRootContext();
  const wexIcon = {
    online: {
      leadingIcon: <IconCheckmarkCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'positive',
      width: '78px',
    },
    offline: {
      leadingIcon: <IconXCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'negative',
      width: '81px',
    },
  };

  const Icon = {
    wex: (
      <Button
        colorScheme={wexIcon[props.state].colorScheme}
        leadingIcon={wexIcon[props.state].leadingIcon}
        customStyle={{
          minWidth: wexIcon[props.state].width,
          width: wexIcon[props.state].width,
          height: '24px',
          maxWidth: '256px',
          borderRadius: '4px',
          gap: '4px',
          padding: '4px 12px 4px 8px',
          pointerEvents: 'none',
        }}
      >
        <WXText>{props.textComponent}</WXText>
      </Button>
    ),
    ecp: (
      <>
        <ConnectivityIcon
          data-testid={'connectivity-renderer-icon'}
          connectionState={props.value}
        />
        <Text>{props.textComponent}</Text>
      </>
    ),
  };

  return <Wrapper>{Icon[isWex ? 'wex' : 'ecp']}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const ConnectivityIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 13px;
  background-color: ${(props) => {
    switch (props.connectionState) {
      case t('table.online'):
        return tokens.color.green6;
      case t('table.offline'):
      default:
        return tokens.color.red6;
    }
  }};
  margin-right: 8px;
  display: inline-block;
  border-radius: 100%;
`;

const Text = styled.div`
  margin-left: 19px;
`;

const WXText = styled.div`
  span {
    font-family: Forma DJR UI !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: center !important;
  }
`;

export default TableConnectivityRenderer;
