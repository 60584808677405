import tokens from '@veneer/primitives';
import { extendSemantics } from '@veneer/semantics';

import DefaultColor from './DefaultColors';

const color = tokens.color;
// import { Colors as ColorPalette } from 'app/config/color_palettes';

const getWexColors = (colors) => {
  return {
    default: colors['0'],
    hover: colors['1'],
    active: colors['2'],
    backgroundLight: colors['0'] + '0D',
    background: colors['0'] + '1A',
    foreground: colors['3'],
  };
};
// Opacity 0 to 9; goes from 0% to 90%; These are hexa suffixes for same.

const opacity = ['00', '1A', '33', '4D', '66', '80', '99', 'B3', 'CC', 'E6'];
const White = color.white;
const getWexColorsv2 = (colors) => {
  return {
    foreground: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
    base: {
      default: colors[0],
      hover: colors[1],
      active: colors[2],
    },
    background: {
      default: colors[4], // Background
      hover: colors[0] + opacity[1], // Background Light
      active: colors[0] + opacity[2], // Background
    },
  };
};
const getContrastWexColors = (colors) => {
  return {
    default: colors['0'],
    hover: colors['1'],
    active: colors['2'],
    backgroundLight: colors.highContrastEigengrau,
    background: colors.highContrastEigengrau,
    foreground: colors['3'],
  };
};
const getContrastWexColorsv2 = (colors) => {
  return {
    foreground: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
    base: {
      default: colors[0],
      hover: colors[1],
      active: colors[2],
    },
    background: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
  };
};
const ColorOverride = {
  wex: {
    light: {
      foreground: {
        default: color.gray12,
        medium: color.gray9,
        light: color.gray7,
        inverse: color.white,
        disabled: color.gray5,
      },
      background: {
        default: color.darkNavy5 + '1A',
        container: color.white,
        disabled: color.gray2,
        shell: color.white,
        // stroke: color.gray3,
      },
      stroke: {
        default: color.darkNavy0, //color.gray3, //'#d9d9d9',
      },
      effects: {
        glasswall: 'rgba(33,33,33,0.5)',
        dropShadow: 'rgba(77, 82, 229, 0.20)',
        highlight: {
          // default: 'rgba(59,183,246,0.1)',
          // hover: 'rgba(115,207,254,0.3)',
          // active: 'rgba(159,224,254,0.6)',
          ...getWexColorsv2([
            color.darkNavy7,
            color.darkNavy8,
            color.darkNavy9,
            color.white,
            color.darkNavy0,
          ]).background,
        },
      },
      spotlight: {
        foreground: {
          default: color.gray12,
          hover: color.gray12,
          active: color.gray12,
        },
        base: {
          default: color.white,
          hover: 'rgba(255,255,255,0.8)',
          active: 'rgba(255,255,255,0.6)',
        },
        background: {
          default: color.gray12,
          hover: 'rgba(255,255,255,0.1)',
          active: 'rgba(255,255,255,0.2)',
        },
      },
      primary: {
        ...getWexColorsv2([
          color.darkNavy7,
          color.darkNavy8,
          color.darkNavy9,
          color.white,
          color.darkNavy0,
        ]),
      },
      accent: {
        foreground: {
          default: color.white,
          hover: color.white,
          active: color.white,
        },
        base: {
          default: color.black,
          hover: color.green11,
          active: color.green9,
        },
        background: {
          default: color.gray0,
          hover: color.gray2,
          active: color.gray3,
        },
      },
      neutral: {
        ...getWexColorsv2([color.gray7, color.gray8, color.gray9, color.white, color.gray1]),
      },
      positive: {
        ...getWexColorsv2([color.green7, color.green8, color.green9, color.white]),
        background: {
          default: color.green0,
          hover: color.green1,
          active: color.green2,
        },
      },
      caution: {
        foreground: {
          default: color.gold9,
          hover: color.gray12,
          active: color.gray12,
        },
        base: {
          default: color.gold3,
          hover: color.gold4,
          active: color.gold5,
        },
        background: {
          default: color.gold0,
          hover: color.gold1,
          active: color.gold2,
        },
      },
      warning: {
        ...getWexColorsv2([color.darkOrange5, color.darkOrange6, color.darkOrange7, color.gray12]),
        background: {
          default: color.darkOrange0,
          hover: color.darkOrange1,
          active: color.darkOrange2,
        },
      },
      negative: {
        ...getWexColorsv2([color.red7, color.red8, color.red9, color.white]),
        background: {
          default: color.red0,
          hover: color.red1,
          active: color.red2,
        },
      },
      help: {
        foreground: {
          default: color.white,
          hover: color.white,
          active: color.white,
        },
        base: {
          default: color.vividSkyBlue7,
          hover: color.vividSkyBlue8,
          active: color.vividSkyBlue9,
        },
        background: {
          default: color.vividSkyBlue0,
          hover: color.vividSkyBlue1,
          active: color.vividSkyBlue2,
        },
      },
    },

    dark: {
      foreground: {
        default: color.white,
        medium: color.gray3,
        light: color.gray5,
        inverse: color.gray12,
        disabled: color.gray7,
      },
      background: {
        default: color.gray12,
        container: color.gray11,
        disabled: color.gray9,
        shell: color.gray12,
        // stroke: color.gray8,
      },
      stroke: {
        default: color.gray9, //'#d9d9d9',
      },
      effects: {
        glasswall: 'rgba(33,33,33,0.7)',
        dropShadow: 'rgba(0,0,0,0.4)',
        highlight: {
          // default: 'rgba(0,115,168,0.1)',
          // hover: 'rgba(1,89,133,0.3)',
          // active: 'rgba(2,71,107,0.6)',
          ...getWexColorsv2([
            color.darkNavy5,
            color.darkNavy4,
            color.darkNavy3,
            color.white,
            color.darkNavy12,
          ]).background,
        },
      },
      spotlight: {
        foreground: {
          default: color.white,
          hover: color.white,
          active: color.white,
        },
        base: {
          default: color.red12,
          hover: color.gray12 + opacity[8], //#212121CC
          active: color.gray12 + opacity[6], //#21212199//'rgba(33,33,33,0.6)',
        },
        background: {
          default: color.white,
          hover: color.gray12 + opacity[1], //#2121211A,
          active: color.gray12 + opacity[2], //#21212133//'rgba(33,33,33,0.2)',
        },
      },
      primary: {
        ...getWexColorsv2([
          color.darkNavy5,
          color.darkNavy4,
          color.darkNavy3,
          color.gray12,
          color.darkNavy12,
        ]),
      },
      accent: {
        foreground: {
          default: color.black,
          hover: color.black,
          active: color.black,
        },
        base: {
          default: color.white,
          hover: color.gray2,
          active: color.gray3,
        },
        background: {
          default: color.gray12,
          hover: color.gray11,
          active: color.gray10,
        },
      },
      neutral: {
        ...getWexColorsv2([color.gray5, color.gray4, color.gray3, color.gray12, color.gray10]),
        background: {
          default: color.gray10,
          hover: color.white + opacity[1],
          active: color.white + opacity[2],
        },
      },
      positive: {
        ...getWexColorsv2([color.green5, color.green4, color.green3, color.gray12]),
        background: {
          default: color.green12,
          hover: color.green11,
          active: color.green10,
        },
      },
      caution: {
        ...getWexColorsv2([color.gold3, color.gold2, color.gold1, color.gray12]),
        background: {
          default: color.gold12,
          hover: color.gold11,
          active: color.gold10,
        },
      },
      warning: {
        ...getWexColorsv2([color.darkOrange5, color.darkOrange4, color.darkOrange3, color.white]),
        background: {
          default: color.darkOrange12,
          hover: color.darkOrange11,
          active: color.darkOrange10,
        },
      },
      negative: {
        ...getWexColorsv2([color.red5, color.red4, color.red3, color.gray12]),
        background: {
          default: color.red12,
          hover: color.red11,
          active: color.red10,
        },
      },
      help: {
        ...getWexColorsv2([
          color.vividSkyBlue5,
          color.vividSkyBlue4,
          color.vividSkyBlue3,
          color.gray12,
        ]),
        background: {
          default: color.vividSkyBlue12,
          hover: color.vividSkyBlue11,
          active: color.vividSkyBlue10,
        },
      },
    },

    contrast: {
      foreground: {
        default: color.white,
        medium: color.white,
        light: color.white,
        inverse: color.highContrastEigengrau,
        disabled: color.highContrastGray,
      },
      background: {
        default: color.highContrastEigengrau,
        container: color.highContrastEigengrau,
        disabled: color.gray8,
        shell: color.highContrastEigengrau,
        // stroke: color.highContrastGray,
      },
      stroke: {
        default: color.highContrastGray,
      },
      effects: {
        glasswall: '#16141d',
        dropShadow: 'rgba(22,20,29,0)',
        highlight: {
          default: 'rgba(22,20,29,0)',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
      },
      spotlight: {
        foreground: {
          default: '#16141d',
          hover: '#16141d',
          active: '#16141d',
        },
        base: {
          default: '#fff700',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
        background: {
          default: color.white,
          hover: '#fe6aff',
          active: '#00d3ff',
        },
      },
      primary: {
        ...getContrastWexColorsv2([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      accent: {
        foreground: {
          default: '#16141d',
          hover: '#16141d',
          active: '#16141d',
        },
        base: {
          default: '#fff700',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
        background: {
          default: '#16141d',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
      },
      neutral: {
        ...getContrastWexColorsv2([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      positive: {
        ...getContrastWexColorsv2([
          color.green5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      caution: {
        foreground: {
          default: '#16141d',
          hover: '#16141d',
          active: '#16141d',
        },
        base: {
          default: '#ffe137',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
        background: {
          default: '#16141d',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
      },
      warning: {
        ...getContrastWexColorsv2([
          color.darkOrange5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      negative: {
        ...getContrastWexColorsv2([
          color.red5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      help: {
        foreground: {
          default: '#16141d',
          hover: '#16141d',
          active: '#16141d',
        },
        base: {
          default: '#2ac5e0',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
        background: {
          default: '#16141d',
          hover: '#fe6aff',
          active: '#00d3ff',
        },
      },
    },
  },

  default: {
    light: {
      primary: {
        background: { default: 'rgba(2, 120, 171, 0.05)' },
        base: { active: '#014667' },
      },
    },
    dark: {},
    contrast: {},
  },
};

const PaletteCache = {};
const OverrideCache = {};
export const getPalette = (mode = 'light', selectedColor = 'wex', customOnly = false) => {
  selectedColor = selectedColor || 'wex';
  if (PaletteCache[selectedColor] && PaletteCache[selectedColor][mode]) {
    return customOnly ? OverrideCache[selectedColor][mode] : PaletteCache[selectedColor][mode];
  } else {
    let _palette = JSON.parse(JSON.stringify(DefaultColor[mode]));
    let _override =
      ColorOverride[selectedColor] && ColorOverride[selectedColor][mode]
        ? ColorOverride[selectedColor][mode]
        : {};
    if (_override) {
      for (let key in _override) {
        _palette[key] = {
          ..._palette[key], //palette is used as standalone as well, so need to add the default values
          ..._override[key],
        };
      }
    }
    PaletteCache[selectedColor] = PaletteCache[selectedColor] || {};
    PaletteCache[selectedColor][mode] = _palette;
    Object.freeze(PaletteCache[selectedColor][mode]);

    // Cache _override for creating custom system
    OverrideCache[selectedColor] = OverrideCache[selectedColor] || {};
    OverrideCache[selectedColor][mode] = _override;
    Object.freeze(OverrideCache[selectedColor][mode]);
    if (customOnly) {
      return _override;
    } else {
      return _palette;
    }
    // return JSON.parse(JSON.stringify(_palette));
  }
};

export const getSystem = (mode = 'light', selectedColor = 'wex') => {
  let color = {
    light: getPalette('light', selectedColor, false),
    dark: getPalette('dark', selectedColor, false),
    contrast: getPalette('contrast', selectedColor, false),
  };
  return extendSemantics({
    color,
  });
};
